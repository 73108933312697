export default class NoticiasEditController {
  constructor(AuthenticationService, AuthorizationService, UIService, $state, $scope, News, Domains, Files, FileUploader, Timelines) {
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.News = News;
    this.edicao = null;
    this.Domains = Domains;
    this.Files = Files;
    this.$state = $state;
    this.FileUploader = FileUploader;
    this.Timelines = Timelines;

    this.id = $state.params.id;
    if (angular.isUndefined(this.id)) {
      $state.go('app.noticias.list');
      this.UI.addToast('Erro a carregar notícia');
    }

    this.loadData();
    // Control variables
    this.newsLoading = true;
    this.form = {};
    this.filename = "";
    this.pubDate = 0;
    this.hasImage = false;
    this.hadImage = false;
    this.isFeatured = false;
    this.file = {};
    this.auxDominio = {
      selected: {}
    };
    // Cropper API
    this.pmdCropper = {};
    $scope.$watch(() => {
      return this.pmdCropper.ready;
    }, (a) => {
      this.hasImage = a;
    });
    // Uploader settings
    this.uploader = new this.FileUploader({
      url: '/api/assets/upload/news',
      queueLimit: 1,
    });
    // Force items to be only image
    let filter = {
      name: 'verifyIMG',
      fn: function (item, options) {
        return item.type.indexOf("image/") !== -1;
      }
    };
    this.uploader.filters.push(filter);
    // Register news if image uploaded ok
    this.uploader.onSuccessItem = (item, response) => {
      this.fileUploadedOK = true;
      this.file.container = response.result.container;
      this.file.year = response.result.year;
      this.file.month = response.result.month;
      this.file.name = response.result.name;
      this.registerNews();
    };
    // Image upload failed
    this.uploader.onErrorItem = (response, status, headers) => {
      this.fileUploadedOK = false;
      this.UI.addToast('Erro ao carregar imagem');
      this.UI.showConfirm('Ocorreu um erro a carregar imagem, deseja inserir notícia sem imagem?').then(r => {
        if (r) {
          this.registerNews();
        }
      }).catch(() => {
      });
    };
  };

  getImage = () => {
    this.UI.addToast('A carregar imagem...');
    let dataURItoBlob = (dataURI) => {
      let byteString = atob(dataURI.toString().split(',')[1]);
      //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      //or mimeString if you want
      return new Blob([ab], {type: 'image/jpeg'});
    };
    let base64 = this.pmdCropper.export();
    let blob = dataURItoBlob(base64);
    let name = `${this.UUID()}.jpg`;
    if (this.filename.length > 0) {
      name = this.filename;
    }
    let file = new File([blob], name);
    // Create "dummy" file and add it to the uploader
    let dummy = new this.FileUploader.FileItem(this.uploader, {
      lastModifiedData: new Date(),
      type: 'image/jpeg',
      name: name
    });
    dummy._file = file;
    this.uploader.queue = [];
    this.uploader.queue.push(dummy);
    this.uploader.uploadAll();
  };

  pickImage = () => {
    angular.element('#fileinput').trigger('click');
  };

  loadData = () => {
    this.newsLoading = true;
    this.Domains.find({
      filter: {
        where: {
          id: {
            inq: this.Authorization.getDomains()
          }
        }
      }
    }).$promise.then(res => {
      this.dominios = res;
      if (res.length === 1) {
        this.auxDominio.selected = res[0];
      }
      this.News.findOne({
        filter: {
          where: {
            id: this.id
          },
          include: ['dominio', 'files', 'timeline']
        }
      }).$promise.then(noticia => {
        // Handle news
        this.news = noticia;
        // Handle select
        this.auxDominio.selected = angular.copy(this.news.dominio);
        delete this.news.dominio;
        // Handle checkboxs
        this.news.extramsg = this.news.extramsg === 1;
        this.news.toshare = this.news.toshare === 1;
        // Handle image
        if (this.news.files.length > 0) {
          this.file = this.news.files[0];
          this.filename = angular.copy(this.news.files[0].name);
          this.hasImage = true;
          this.hadImage = true;
        }
        // Handle timeline
        if (angular.isDefined(this.news.timeline)) {
          this.news.timeline.featured = this.news.timeline.featured === 1;
        }
        this.newsLoading = false;
      }).catch(() => {
        this.newsLoading = false;
        this.$state.go('app.noticias.list');
        this.UI.addToast('Erro a carregar notícia');
      });
    });
  };

  UUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  submit = () => {
    if (this.form.editNoticia.$invalid) {
      this.UI.addToast('Existem erros no preenchimento do formulário');
    } else {
      if (!this.hadImage && !this.hasImage) {
        this.registerNews();
      } else if (this.hadImage && !this.hasImage) {
        this.removeImage();
      } else {
        this.getImage();
      }
    }
  };

  removeImage = () => {
    this.Files.destroyById({id: this.file.id}).$promise.then(() => {
      this.registerNews();
    }).catch(() => {
      this.registerNews();
    });
  };

  registerNews = () => {
    // Check if this is really used...
    if (this.news.toshare) {
      this.news.tosharedc = true;
      this.news.toshareda = true;
      this.news.tosharedl = true;
      this.news.tosharedv = true;
    }
    if (angular.isDefined(this.news.timeline)) {
    } else {

    }
    this.Timelines.upsert(this.news.timeline);
    this.news.domainId = this.auxDominio.selected.id;
    this.News.upsert(this.news).$promise.then(news => {
      this.file.newId = news.id;
      if (this.fileUploadedOK) {
        this.file.id = this.file.id || 0;
        this.file.domainId = this.auxDominio.selected.id;
        this.file.userId = this.Auth.getId();
        // For now...
        this.file.path = `api/assets/download/${this.file.container}/${this.file.year}/${this.file.month}/${this.file.name}`;
        this.file.filetype = this.uploader.queue[0].file.type;
        this.Files.upsert(this.file).$promise.then(() => {
          this.leave();
        });
      } else {
        this.leave();
      }
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro a adicionar notícia');
    });
  };

  leave = () => {
    this.UI.addToast('Notícia editada com sucesso');
    this.$state.go('app.noticias.list');
  };

  clearImage = () => {
    this.hasImage = false;
    this.pmdCropper.clear();
  };
};

NoticiasEditController.$inject = ['AuthenticationService', 'AuthorizationService', 'UIService', '$state', '$scope', 'News', 'Domains', 'Files', 'FileUploader', 'Timelines'];
