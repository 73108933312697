import {routes} from './newsletter.routes';
import NewsletterListController from "./directory/directory.controller";
import NewsletterInsertController from "./new/new.controller";
import ClientesController from "./clientes/list/cliente.controller";
import ClientesDetalhesController from "./clientes/details/clienteDetalhes.controller";
import DominioController from "./dominios/dominios.controller";
import NewsletterController from "./noticias/envioNewsletter/envioNewsletter.controller";
import ListNewsletterController from "./newsletters/newsletter.controller";
import DetalhesDominioController from "./dominios/detalhesDominio/detalhesdominio.controlador";
import WebpagesListController from "./webpages/webpage.controller";
import DetalhesWebpagesController from "./webpages/details/detalhesPagina.controller";
import DetalhesModalidadesController from "./modalidades/detalhesModalidade/detalhesModalidade.controller";
import ModalidadesController from "./modalidades/modalidades.controller";
import PublicidadeController from "./publicidade/publicidade.controller";
import NewPublicidadeController from "./publicidade/new/newPublicidade.controller";
import DetalhesPublicidadeController from "./publicidade/detalhesPublicidade/detalhesPublicidade.controller";
import EdicoesListController from "./edicoes/list/list.controller";
import EdicoesDetailsController from "./edicoes/details/details.controller";
import NewEditionController from "./edicoes/new/new.controller";
import EditEditionController from "./edicoes/details/edit/edit.controller";
import DetalhesNewsletterController from "./newsletters/detailsNewsletter/detailsNewsletter.controller";
import SincronizacaoPHCController from "./phc/phc.controller";


export default angular.module('app.teasers', [])
  .config(routes)
  .controller('NewsletterListController', NewsletterListController)
  .controller('NewsletterInsertController', NewsletterInsertController)
  .controller('ClientesController', ClientesController)
  .controller('ClientesDetalhesController', ClientesDetalhesController)
  .controller('DominioController', DominioController)
  .controller('NewsletterController', NewsletterController)
  .controller('ListNewsletterController', ListNewsletterController)
  .controller('DetalhesDominioController', DetalhesDominioController)
  .controller('WebpagesListController', WebpagesListController)
  .controller('DetalhesWebpagesController', DetalhesWebpagesController)
  .controller('ModalidadesController', ModalidadesController)
  .controller('DetalhesModalidadesController', DetalhesModalidadesController)
  .controller('PublicidadeController', PublicidadeController)
  .controller('NewPublicidadeController', NewPublicidadeController)
  .controller('DetalhesPublicidadeController', DetalhesPublicidadeController)
  .controller('EdicoesListController', EdicoesListController)
  .controller('EdicoesDetailsController', EdicoesDetailsController)
  .controller('NewEditionController', NewEditionController)
  .controller('EditEditionController', EditEditionController)
  .controller('DetalhesNewsletterController', DetalhesNewsletterController)
  .controller('SincronizacaoPHCController', SincronizacaoPHCController)
  .name;
