export default class DominioController {
  constructor(Webpages, Domains, $http, NgTableParams, $rootScope, $state, $filter, FileUploader, UIService) {
    this.paginas = null;
    this.dominios = null;
    this.Webpages = Webpages;
    this.Domains = Domains;
    this.paginasLoading = true;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.opt = $state.params;
    this.$state = $state;
    this.$filter = $filter;
    this.getPaginas();
    this.getDominios();
  };


  getDominios = () => {
    this.Domains.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.dominios = res;
    }).catch((error) => {
      console.log(error);
    })
  };

  getPaginas = () => {
    this.paginasLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    let whereFilter = {
      active: 1
    };
    this.Webpages.count({
      fields: {id: true},
      where: whereFilter
    }).$promise.then((res) => {
      this.total = res.count;
      this.Webpages.find({
        filter: {
          fields: {
            body: false
          },
          where: whereFilter,
          order: order,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
          include: {
            relation: 'dominio',
            scope: {
              where: {active: 1}
            }
          }
        }
      }).$promise.then(pagina => {
        let page = this.opt.page;
        let items = this.opt.items;
        let total = this.total;
        this.start = (page - 1) * items + 1;
        // Text
        if ((this.start - 1 + items + items) >= total) {
          this.end = total;
        } else {
          this.end = Number.parseInt(this.start - 1 + items);
        }
        this.paginas = pagina;
        this.paginas.forEach(p => {
          if (this.opt.sort === 'asc') {
            p.index = this.start + this.paginas.indexOf(p);
          } else {
            p.index = this.total - this.start - this.paginas.indexOf(p) + 1;
          }
        });
        this.paginasLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.webpages.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPaginas();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.webpages.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPaginas();
  };

  removerPagina = (a) => {
    this.Webpages.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./removerPagina.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Página removida com sucesso!");
          this.getPaginas();
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  createPagina = () => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarPagina.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.categorias = {};
        $scope.domainsOptions = self.dominios;
        $scope.domainsOption = {};
        $scope.label = "Adicionar Página";
        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.Webpages.create({
        id: 0,
        title: ok.pagina.title,
        body: ok.pagina.body,
        domainId: ok.domainsOption.id,
        active: 1
      }).$promise.then((res) => {
        this.getPaginas();
        this.UI.addToast("Página adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação da página!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editarPagina = (m) => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarPagina.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.pagina = {};
        $scope.pagina.id = m.id;
        $scope.pagina.title = m.title;
        $scope.pagina.body = m.body;
        $scope.domainsOptions = self.dominios;
        $scope.domainsOption = m.dominio;

        $scope.label = "Editar Página";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);
    dialogEdit.then((a) => {
      this.Webpages.findOne({
        filter: {
          where: {
            id: a.pagina.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.title = a.pagina.title;
        res.id = a.pagina.id;
        res.body = a.pagina.body;
        res.domainId = a.domainsOption.id;
        res.$save().then((r) => {
          this.getPaginas();
          this.UI.addToast("Página genérica editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da página genérica!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };
};

DominioController.$inject = ['Webpages', 'Domains', '$http', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', 'UIService'];
