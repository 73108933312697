export default class EdicoesListController {
  constructor(AuthorizationService, Timelines, UIService, $state, NgTableParams, Issues, $filter) {
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.Issues = Issues;
    this.timeline = null;
    this.Timelines = Timelines;
    this.NgTableParams = NgTableParams;
    this.$filter = $filter;
    this.edicaoLoading = true;
    this.$state = $state;
    this.opt = $state.params;
    this.getEdicao();
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  removerEdicao = (a) => {
    this.UI.showConfirm(`Deseja remover esta edição do ${a.magazines.dominio.name}?`).then(res => {
      if(res) {
        a.active = 0;
        a.uuid = this.generateUUID();
        a.title = a.dateIssued;
        this.Issues.upsert(a).$promise.then(() => {
          this.getEdicao();
          this.UI.addToast("Edição removida com sucesso!");
        });
      }
    });
  };

  getEdicao = () => {
    this.edicaoLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    let whereFilter = {
      active: 1,
      domainId: {
        inq: this.Authorization.getDomains()
      }
    };
    this.Issues.count({
      fields: {id: true},
      where: whereFilter
    }).$promise.then((res) => {
      this.total = res.count;
      this.Issues.find({
        filter: {
          fields: {
            uuid: false,
            appleCode: false,
            size: false,
            title: false,
            price: false
          },
          where: whereFilter,
          order: order,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
          include: [{
            relation: 'magazines',
            scope: {
              where: {active: 1},
              include: {
                relation: 'dominio',
                scope: {
                  fields: {name: true},
                  where: {active: 1}
                }
              }
            }
          }]
        }
      }).$promise.then(issues => {
        let page = this.opt.page;
        let items = this.opt.items;
        let total = this.total;
        this.start = (page - 1) * items + 1;
        // Text
        if (((page-1) * items + items) >= total)
          this.end = total;
        else
          this.end = Number.parseInt(this.start - 1 + items);

        this.edicao = issues;
        this.edicao.forEach(p => {
          if (this.opt.sort === 'asc') {
            p.index = this.start + this.edicao.indexOf(p);
          } else {
            p.index = this.total - this.start - this.edicao.indexOf(p) + 1;
          }
        });
        this.edicaoLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.edicoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getEdicao();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.edicoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getEdicao();
  };
};

EdicoesListController.$inject = ['AuthorizationService', 'Timelines', 'UIService', '$state', 'NgTableParams', 'Issues', '$filter'];
