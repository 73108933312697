export default class NotificationListController {
  constructor(NotificationService, UIService, $state) {
    this.NotificationService = NotificationService;
    this.UI = UIService;
    this.$state = $state;

    this.filter = {
      term: $state.params.term || "",
      page: $state.params.page || 1,
      order: $state.params.order || 'id',
      sort: $state.params.sort || 'desc',
    };


    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.$state.go('app.notifications.list', this.filter, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: true
    });
    this.NotificationService.list(this.createFilter()).then(r => {
      this.total = r.total;
      if (r.data.length > 0) {
        this.start = 1 + (this.filter.page - 1) * 15;
        this.end = (this.start + r.data.length) > this.total ? this.total : this.start + r.data.length;
      } else {
        this.start = 0;
        this.end = 0;
      }
      if ((this.start === 0 || this.end === 0) && this.filter.page > 1) {
        this.filter.page = 1;
        this.loadData();
      }
      this.data = r.data;
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast('Ocorreu um erro a carregar dados!');
    })
  }

  sort = field => {
    if (this.filter.order == field) {
      if (this.filter.sort == 'asc') {
        this.filter.sort = 'desc';
      } else {
        this.filter.sort = 'asc';
      }
    } else {
      this.filter.order = field;
    }
    this.loadData();
  }

  clear = () => {
    this.filter = {
      page: 1,
      term: undefined,
      order: 'name.pt',
      sort: 'asc',
      tag: undefined
    }
    this.loadData();
  };

  createFilter = () => {
    let where = {};

    return {
      where: where,
      limit: 15,
      order: `${this.filter.order} ${this.filter.sort}`,
      skip: (this.filter.page - 1) * 15,
      include: 'domain'
    }
  }

  previous = () => {
    this.filter.page--;
    if (this.filter.page === 0)
      this.filter.page = 1;
    this.loadData();
  };

  next = () => {
    this.filter.page++;
    if (this.filter.page > Math.round(this.total / 15))
      this.filter.page = Math.round(this.total / 15);
    this.loadData();
  }

  remove = (item) => {
    this.UI.showConfirm("Deseja apagar notificação?").then(() => {
      this.NotificationService.delete(item).then((i) => {
        console.log(i);
        this.loadData();
        this.UI.addSnackbar("Notificação eliminada", "Anular", true).then((a) => {
          if (a) {
            item.active = true;
            delete item.id;
            this.NotificationService8.save(item);
            this.loadData();
          }
        });
      });
    });
  };
}

NotificationListController.$inject = ['NotificationService', 'UIService', '$state'];
