import angular from 'angular'

let md5 = require('md5');

export default angular.module('app.interface.avatar', [])
  .factory('Avatar', function () {

    function color(string) {
// Material Design colors (500 + 600 + 700 + 900)
      const palette = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#9e9e9e", "#607d8b",
        '#e53935', '#d81b60', '#8e24aa', '#5e35b1', '#3949ab', '#1e88e5', '#039be5', '#00acc1', '#00897b', '#43a047', '#7cb342', '#c0ca33', '#fdd835', '#ffb300', '#fb8c00', '#f4511e', '#6d4c41', '#757575', '#546e7a',
        '#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7', '#00796b', '#388e3c', '#689f38', '#afb42b', '#fbc02d', '#ffa000', '#f57c00', '#e64a19', '#5d4037', '#616161', '#455a64',
        '#b71c1c', '#880e4f', '#4a148c', '#311b92', '#1a237e', '#0d47a1', '#01579b', '#006064', '#004d40', '#1b5e20', '#33691e', '#827717', '#f57f17', '#ff6f00', '#e65100', '#bf360c', '#3e2723', '#212121', '#263238'];
      let i,
        charIndex = 0;

      for (i = 0; i < string.length; i++) charIndex += string.charCodeAt(i);
      return palette[charIndex % palette.length];
    };

    function initials(string) {
      let output = "",
        i = 0,
        str = (string.split(" ").length >= 2) ? [string.split(" ").shift(), string.split(" ").pop()] : string.split(),
        len = str.length;

      for (i; i < len; i++) if (str[i] != "") output += str[i][0]; //.toUpperCase();
      return output;
    };

    return {
      getInitials: (string) => {
        return initials(string);
      },
      getColor: (string) => {
        return color(string);
      }
    }
  })
  .directive('pmdAvatar', ['Avatar', (Avatar) => {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=?user',
        string: '@?string',
        size: '@size',
      },
      link: function (scope, element, attrs) {

        if (angular.isUndefined(scope.size))
          scope.size = '50px';

        let name = "";

        if (angular.isDefined(scope.user)) {
          name = Avatar.getInitials(scope.user.name);
        } else if (angular.isDefined(scope.string)) {
          name = Avatar.getInitials(scope.string);
        }

        function generateImage() {
          let SIZE = 256, canvas, ctx, fontSize;

          // Create a canvas
          canvas = document.createElement('canvas');
          canvas.id = 'avatar-' + Date.now();
          canvas.width = SIZE;
          canvas.height = SIZE;

          // Filling the canvas
          ctx = canvas.getContext('2d');
          ctx.fillStyle = Avatar.getColor(name);
          ctx.fillRect(0, 0, SIZE, SIZE);

          // Adding text to the canvas
          fontSize = SIZE / 2;
          ctx.font = "100 " + fontSize + "px sans-serif";
          ctx.shadowColor = "black";
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          ctx.shadowBlur = 5;

          ctx.textAlign = "center";
          ctx.fillStyle = "#fff";
          ctx.fillText(name, SIZE / 2, SIZE - (SIZE / 2) + (fontSize / 3));

          // Return canvas as a "PNG"
          return canvas.toDataURL("image/png");
        }

        let currentElement = element;

        function render() {
          let imgData = generateImage();
          let url = "";
          if (angular.isDefined(scope.user)) {
            url = 'https://s.gravatar.com/avatar/' + md5(scope.user.email) + '?s=' + scope.size.split('px')[0] * 2 + '&d=404';
          } else {
            url = imgData;
          }
          let html = '<img alt="user-profile" src="' + url + '" width="' + scope.size + '" height="' + scope.size + '">';
          let replacementElement = angular.element(html);
          replacementElement.bind('error', () => {
            replacementElement.prop('src', imgData);
          });
          currentElement.replaceWith(replacementElement);
          currentElement = replacementElement;

        }

        scope.$watch('string', (value) => {
          render();
        });
        scope.$watch('user', (value) => {
          render();
        });
      }
    }
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Avatar',
      description: 'Name based avatar with Gravatar integration',
      version: '1.1.6'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
