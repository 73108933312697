export default class DetalhesPublicidadeController {
  constructor(
    Publicitys,
    Domains,
    $http,
    NgTableParams,
    $rootScope,
    $state,
    $filter,
    FileUploader,
    UIService,
    $stateParams
  ) {
    this.publicidade = null;
    this.dominios = null;
    this.Publicitys = Publicitys;
    this.Domains = Domains;
    this.publicidadeLoading = true;
    this.id = $stateParams.id;
    this.UI = UIService;
    this.$http = $http;
    this.NgTableParams = NgTableParams;
    this.FileUploader = FileUploader;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.getDetalhesPublicidade(this.id);
    this.getDominios();
  }

  getDetalhesPublicidade = (id) => {
    this.publicidadeLoading = true;
    this.Publicitys.findOne({
      filter: {
        where: {
          id: id,
          active: 1,
        },
        include: [
          {
            relation: "dominio",
            scope: {
              where: { active: 1 },
            },
          },
          {
            relation: "files",
            scope: {
              where: { active: 1 },
            },
          },
        ],
      },
    })
      .$promise.then((res) => {
        this.publicidade = res;

        if (res.files.length > 0) {
          this.pubImage = res.files[0];
        }

        this.publicidadeLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDominios = () => {
    this.Domains.find({
      filter: {
        where: {
          active: 1,
        },
      },
    })
      .$promise.then((res) => {
        this.dominios = res;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

DetalhesPublicidadeController.$inject = [
  "Publicitys",
  "Domains",
  "$http",
  "NgTableParams",
  "$rootScope",
  "$state",
  "$filter",
  "FileUploader",
  "UIService",
  "$stateParams",
];
