export default class NotificationDetailsController {
  constructor(NotificationService, AuthorizationService, Domains, UIService, $state, $scope) {
    this.NotificationService = NotificationService;
    this.Authorization = AuthorizationService;
    this.Domains = Domains;
    this.UI = UIService;
    this.$state = $state;

    $scope.$watch(() => {
      return this.aux;
    }, (val) => {
      if (val.value !== 1) {
        delete this.data.data;
      } else {
        if (angular.isUndefined(this.data.data)) {
          this.date.data = moment();
        }
      }
    })

    this.options = [{
      value: 0,
      text: "Rascunho"
    }, {
      value: 1,
      text: "Agendar"
    }, {
      value: 2,
      text: "Enviar agora"
    }];

    this.id = $state.params.id;

    this.loadDomains();

    if (!this.id) {
      this.data = {
        enviada: 0,
      };
      this.aux = this.options[0];
      this.loaded = true;
    } else {
      this.loadData();
    }
  }

  loadDomains = () => {
    this.Domains.find({
      filter: {
        where: {
          id: {
            inq: this.Authorization.getDomains()
          }
        }
      }
    }).$promise.then(res => {
      this.dominios = res;
      if (res.length === 1) {
        this.auxDominio.selected = res[0];
      }
    });
  };

  loadData = () => {
    this.NotificationService.get(this.id).then(r => {
      this.data = r;
      this.aux = this.options.find(e => e.value === r.estado);
      this.auxDominio = this.dominios.find(e => e.id === r.domainId);
      this.loaded = true;
    }).catch(() => {});
  }

  save = () => {
    if (this.validate() !== null) {
      this.UI.addToast(this.validate());
      return;
    }
    this.data.domainId = this.auxDominio.id;
    this.data.estado = this.aux.value || 0;
    delete this.data.aux;
    if (this.data.estado === 2) {
      this.data.data = moment();
    }
    this.data.enviada = this.data.enviada || 0;
    this.data.tentativas = this.data.tentativas || 0;
    this.data.id = this.data.id || 0;
    this.NotificationService.save(this.data).then((r) => {
      if (this.id) {
        this.UI.addToast("Notificação atualizada com sucesso");
      } else {
        this.UI.addToast("Notificação adicionada com sucesso");
      }
      this.$state.go('app.notifications.list');
    });
  }

  validate = () => {

    if (this.aux.value === 1 && !this.data.data) {
      return 'Escolha a data a que pretende enviar a notificação';  
    }

    if (!this.data.titulo) {
      return 'Insira o título da notificação';
    }

    if (!this.data.mensagem) {
      return 'Insira a mensagem da notificação';
    }

    if (!this.auxDominio) {
      return 'Escolha o jornal para qual pretende enviar a notificação';
    }

    return null;
  }
}

NotificationDetailsController.$inject = ['NotificationService', 'AuthorizationService', 'Domains', 'UIService', '$state', '$scope'];
