export function routes($stateProvider) {
  $stateProvider
    .state("app.ads", {
      template: "<ui-view></ui-view>",
      url: "/ads",
      abstract: true,
    })
    .state("app.ads.list", {
      url: "?{page:int}&{items:int}&order&sort&filter",
      title: "Publicidade",
      role: "pubList",
      template: require("./list/view.html"),
      controller: "AdsListController",
      controllerAs: "vm",
      params: {
        page: {
          value: 1,
          dynamic: true,
        },
        items: {
          value: 20,
          dynamic: true,
        },
        order: {
          value: "dateFinal",
          dynamic: true,
        },
        sort: {
          value: "desc",
          dynamic: true,
        },
        filter: {
          dynamic: true,
        },
      },
    })
    .state("app.ads.add", {
      template: require("./add/view.html"),
      title: "Adicionar Publicidade",
      role: "pubAdd",
      url: "/adicionar",
      controller: "AdsNewController",
      controllerAs: "vm",
    })
    .state("app.ads.details", {
      template: require("./view/view.html"),
      title: "Visualizar Publicidade",
      role: "pubAdd",
      url: "/{id}",
      controller: "DetalhesPublicidadeController",
      controllerAs: "vm",
    });
}

routes.$inject = ["$stateProvider"];
