import angular from 'angular';

import {routes} from './interface.routes';
import LayoutController from './layout/layout.controller';
import ProfileController from './profile/profile.controller';
import ChangelogController from "./changelog/changelog.controller";
import ChangelogEditorController from "./changelog/change/change.controller";
import UIService from './UI.service';
import DashboardController from "./dashboard/dashboard.controller";
import ChangelogService from "./changelog/changelog.service";
import fileSize from "filesize";
import DirectiveLoader from "./directive.loader";

export default angular.module('app.interface', [DirectiveLoader])
  .config(routes)
  .controller('LayoutController', LayoutController)
  .controller('ProfileController', ProfileController)
  .controller('ChangelogController', ChangelogController)
  .controller('ChangelogEditorController', ChangelogEditorController)
  .controller('DashboardController', DashboardController)
  .service('UIService', UIService)
  .service('ChangelogService', ChangelogService)
  .filter('filesize', () => {
    return value => {
      if (value === null) {
        return "N/D";
      } else {
        // Return in mb
        return fileSize(Number.parseInt(value), {exponent: 2});
      }
    }
  })
  .filter('moment', () => {
    return (value, format) => {
      return moment(value).format(format);
    }
  })
  .filter('dateDiff', () => {
    return (value, preholder) => {
      // Calculate diference from given date to now
      return moment(value).fromNow(preholder);
    }
  })
  .filter('skip', () => {
    return (input, start) => {
      // Limit exists, but skip doesn't...
      start = +start; // Parse to int :)
      return input.splice(start);
    }
  })
  .filter('nl2br', ['$sanitize', function ($sanitize) {
    var tag = (/xhtml/i).test(document.doctype) ? '<br />' : '<br>';
    return function (msg) {
      // ngSanitize's linky filter changes \r and \n to &#10; and &#13; respectively
      msg = (msg + '').replace(/(\r\n|\n\r|\r|\n|&#10;&#13;|&#13;&#10;|&#10;|&#13;)/g, tag + '$1');
      return $sanitize(msg);
    };
  }])
  .filter('secondsToTime', function () {

    function padTime(t) {
      return t < 10 ? "0" + t : t;
    }

    return function (_seconds) {
      if (typeof _seconds !== "number" || _seconds < 0)
        return "00:00:00";

      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);

      return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
    };
  })
  .directive('checkAvailability', ['$q', ($q) => {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				model: '=',
				field: '@'
			},
			link: (scope, el, attr, control) => {
				let initialValue;
				if (angular.isUndefined(scope.field)) {
					throw 'Setup field name for directive!'
				}
				if (angular.isUndefined(scope.model)) {
					throw 'Setup model for directive!'
				}
				// Server-side validation
				control.$asyncValidators.available = (model, view) => {
					if (angular.isUndefined(initialValue) && angular.isDefined(view))
						initialValue = view;
					if (view !== initialValue) {
						let deferred = $q.defer();

						let query = {};
						query[scope.field] = view;

						scope.model.count({
							where: query
						}).$promise.then((res) => {
							if (res.count > 0)
								deferred.reject();
							else
								deferred.resolve();
						}).catch(() => {
							// if error, returs ok (server-side validation required!)
							deferred.resolve();
						});

						return deferred.promise;
					} else
						return $q.resolve();
				}
			}
		}
	}])
  .name;
