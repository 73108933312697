import { routes } from "./routes";

import AdsListController from "./list/controller";
import AdsNewController from "./add/controller";

export default angular
  .module("app.ads", [])
  .config(routes)
  .controller("AdsNewController", AdsNewController)
  .controller("AdsListController", AdsListController).name;
