export default class DetalhesNewsletterController {
  constructor(
    Newsletter,
    NgTableParams,
    $rootScope,
    $state,
    $stateParams,
    $filter,
    UIService,
    $http
  ) {
    this.newsletter = null;
    this.id = $stateParams.id;
    this.$http = $http;
    this.Newsletter = Newsletter;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.newsletterLoading = true;
    this.getDetalhesNewsletter(this.id);
  }

  getDetalhesNewsletter = (id) => {
    this.newsletterLoading = true;
    this.Newsletter.findOne({
      filter: {
        where: {
          id: id,
          active: 1,
        },
        include: [
          {
            relation: "list",
          },
          {
            relation: "dominio",
            scope: {
              where: {
                active: 1,
              },
            },
          },
          {
            relation: "newDestaque",
            scope: {
              where: {
                active: 1,
              },
            },
          },
          {
            relation: "news",
            scope: {
              where: {
                active: 1,
              },
              include: {
                relation: "files",
              },
            },
          },
          {
            relation: "publicity",
            scope: {
              where: {
                active: 1,
              },
              include: {
                relation: "files",
              },
            },
          },
          {
            relation: "message",
          },
        ],
      },
    })
      .$promise.then((res) => {
        this.newsletter = res;

        this.table = new this.NgTableParams(
          {
            count: 20,
            sorting: {
              createdate: "desc",
            },
          },
          {
            dataset: this.newsletter.news,
          }
        );
        this.newsletterLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

DetalhesNewsletterController.$inject = [
  "Newsletter",
  "NgTableParams",
  "$rootScope",
  "$state",
  "$stateParams",
  "$filter",
  "UIService",
  "$http",
];
