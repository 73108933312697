export default class DetalhesDominioController {
  constructor(Domains, Categories, Webpages, Tags, Modalidades, GroupNewsstand, Magazines, $stateParams, $http, NgTableParams, $rootScope, $state, $filter, FileUploader, UIService) {
    this.dominio = null;
    this.modalidades = null;
    this.group = null;
    this.paginas = null;
    this.tags = null;
    this.magazine = null;
    this.Domains = Domains;
    this.Tags = Tags;
    this.Categories = Categories;
    this.Webpages = Webpages;
    this.GroupNewsstand = GroupNewsstand;
    this.Magazines = Magazines;
    this.Modalidades = Modalidades;
    this.categorias = null;
    this.id = $stateParams.id;
    this.$http = $http;
    this.dominioDetailsLoading = false;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.FileUploader = FileUploader;
    this.$state = $state;
    this.$filter = $filter;
    this.getDetalhesDominio(this.id);
    this.getCategorias();
    this.getWebpages();
    this.getTags();
  };

  getDetalhesDominio = (id) => {
    this.dominioDetailsLoading = true;
    this.Domains.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: [
          {
            relation: 'magazines',
            scope: {
              where: {
                active: 1,
              },
              include: [{
                relation: 'Climagazines',
                scope: {
                  include: {
                    relation: 'ClientesRel',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }
                }
              },
                {
                  relation: 'modalidades',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }]
            }
          },
          {
            relation: 'categoria',
            scope: {
              where: {
                active: 1
              }
            }
          },
          {
            relation: 'webpages',
            scope: {
              where: {
                active: 1
              }
            }
          },
          {
            relation: 'tags',
            scope: {
              where: {
                active: 1
              }
            }
          }]
      }
    }).$promise.then((res) => {

      this.dominio = res;

      this.table = new this.NgTableParams({
        sorting: {
          id: 'asc'
        }
      }, {
        dataset: this.dominio
      });

      this.tableModalidades = new this.NgTableParams({
        sorting: {
          id: 'asc'
        }
      }, {
        dataset: this.dominio.magazines.modalidades
      });

      this.dominioDetailsLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  updateAnexo = (ok) => {
    this.Domains.findOne({
      filter: {
        where: {
          id: ok.dominio.id,
          active: 1
        }
      }
    }).$promise.then((c) => {
      // If there is no file yet, just upload it
      if (c.logoPath == null) {
        c.logoPath = ok.newFileName;
      } else {
        // There's a file already there. Change it.

        if (ok.newFileName) {
          // Remove old photo
          this.$http.delete('/api/Upload/files/files/' + c.logoPath);
          c.logoPath = ok.newFileName;
        } else
          c.logoPath = null;
      }
      ok.uploader.uploadAll();
      c.$save().then((res) => {
        this.getDetalhesDominio(this.id);
      }, (error) => {
        this.UI.addToast("Erro na gravação da imagem. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível adicionar a imagem. Por favor tente mais tarde.");
    });
  };

  editDominioDetalhes = (appEdit) => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./editarDominioDetalhes.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.dominio = {};
        $scope.dominio.id = appEdit.id;
        $scope.dominio.name = appEdit.name;
        $scope.dominio.location = appEdit.location;
        $scope.dominio.logoPath = appEdit.logoPath;
        $scope.dominio.iconPath = appEdit.iconPath;
        $scope.dominio.homeUrl = appEdit.homeUrl;
        $scope.dominio.localUrl = appEdit.localUrl;
        $scope.dominio.facebookUrl = appEdit.facebookUrl;
        $scope.dominio.twitterUrl = appEdit.twitterUrl;

        $scope.label = "Editar jornal";
        $scope.newFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/files/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };


        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Domains.findOne({
        filter: {
          where: {
            id: a.dominio.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.dominio.name;
        res.location = a.dominio.location;
        res.logoPath = a.dominio.logoPath;
        res.iconPath = a.dominio.iconPath;
        res.homeUrl = a.dominio.homeUrl;
        res.localUrl = a.dominio.localUrl;
        res.facebookUrl = a.dominio.facebookUrl;
        res.twitterUrl = a.dominio.twitterUrl;
        res.$save().then((r) => {
          if (a.newFileName)
            this.updateAnexo(a);
          else
            this.getDetalhesDominio(this.id);
          this.UI.addToast("Jornal editado com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro a editar jornal!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  getTags = () => {
    this.dominioDetailsLoading = true;
    this.Tags.find({
      filter: {
        where: {
          domainId: this.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.tags = res;

      this.tableTags = new this.NgTableParams({
        count: 20,
        sorting: {
          id: 'asc'
        }
      }, {
        dataset: this.tags
      });
      this.dominioDetailsLoading = false;

    }).catch((error) => {
      console.log(error);
    })
  };

  createTag = () => {
    let options = {
      size: 'lg',
      template: require('./adicionarTag.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.categorias = {};
        $scope.label = "Adicionar Tag";
        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {

      this.Tags.create({
        id: 0,
        name: ok.tags.name,
        domainId: this.id,
        designation: ok.tags.designation,
        featured: ok.tags.featured,
        active: 1
      }).$promise.then((res) => {
        this.getTags();
        this.getDetalhesDominio(this.id);
        this.UI.addToast("Tag adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação da tag!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editTag = (m) => {
    let options = {
      size: 'lg',
      template: require('./adicionarTag.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.tags = {};
        $scope.tags.id = m.id;
        $scope.tags.name = m.name;
        $scope.tags.designation = m.designation;

        $scope.label = "Editar Tag";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Tags.findOne({
        filter: {
          where: {
            id: a.tags.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.tags.name;
        res.id = a.tags.id;
        res.designation = a.tags.designation;
        res.$save().then((r) => {
          this.getTags();
          this.UI.addToast("Tag editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da tag!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  removeTag = (a) => {
    this.Tags.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./modalRemover.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.label = "Tem a certeza que pretende remover esta tag?";

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Tag removida com sucesso!");
          this.getTags();
          this.getDetalhesDominio(this.id);
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

 removeModalidade = (a) => {
    this.Modalidades.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./modalRemover.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.label = "Tem a certeza que pretende remover esta modalidade?";

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Modalidade removida com sucesso!");
          this.getDetalhesDominio(this.id);
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  getWebpages = () => {
    this.dominioDetailsLoading = true;
    this.Webpages.find({
      filter: {
        where: {
          domainId: this.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.paginas = res;

      this.tablePaginas = new this.NgTableParams({
        count: 20,
        sorting: {
          id: 'asc'
        }
      }, {
        dataset: this.paginas
      });
      this.dominioDetailsLoading = false;

    }).catch((error) => {
      console.log(error);
    })
  };

  getCategorias = () => {
    this.dominioDetailsLoading = true;
    this.Categories.find({
      filter: {
        where: {
          domainId: this.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.categorias = res;

      this.tableCategorias = new this.NgTableParams({
        count: 20,
        sorting: {
          id: 'asc'
        }
      }, {
        dataset: this.categorias
      });
      this.dominioDetailsLoading = false;

    }).catch((error) => {
      console.log(error);
    })
  };

  createCategoria = () => {
    let options = {
      size: 'lg',
      template: require('./adicionarCategoria.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.categorias = {};
        $scope.label = "Adicionar Categoria";
        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {

      this.Categories.create({
        id: 0,
        name: ok.categorias.name,
        domainId: this.id,
        description: ok.categorias.description,
        active: 1
      }).$promise.then((res) => {
        this.getCategorias();
        this.getDetalhesDominio(this.id);
        this.UI.addToast("Categoria adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação da categoria!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editCategoria = (m) => {
    let options = {
      size: 'lg',
      template: require('./adicionarCategoria.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.categorias = {};
        $scope.categorias.id = m.id;
        $scope.categorias.name = m.name;
        $scope.categorias.description = m.description;

        $scope.label = "Editar categoria";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Categories.findOne({
        filter: {
          where: {
            id: a.categorias.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.categorias.name;
        res.id = a.categorias.id;
        res.description = a.categorias.description;
        res.$save().then((r) => {
          this.getCategorias();
          this.UI.addToast("Categoria editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da categoria!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  removeCategoria = (a) => {
    this.Categories.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'lg',
        template: require('./modalRemover.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.label = "Tem a certeza que pretende remover esta categoria?";

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Categoria removida com sucesso!");
          this.getCategorias();
          this.getDetalhesDominio(this.id);
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };
};

DetalhesDominioController.$inject = ['Domains', 'Categories', 'Webpages', 'Tags', 'Modalidades', 'GroupNewsstand', 'Magazines', '$stateParams', '$http', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', 'UIService'];
