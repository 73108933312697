export default class SincronizacaoPHCController {
  constructor(FileUploader, Domains, $filter, UIService, NgTableParams) {
    this.Domains = Domains;
    this.FileUploader = FileUploader;
    this.$filter = $filter;
    this.ficheiroLido = undefined;
    this.NgTableParams = NgTableParams;
    this.text = null;
    this.jornal = null;
    this.newText = null;
    this.UI = UIService;
    this.uploadFileCSV();
  };

  uploadFileCSV = () => {
    this.file = {
      filetype: 'csv',
    };

    let filter = {
      name: 'verifyCSV',
      fn: function (item, options) {
        let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|csv|'.indexOf(type) !== -1;
      }
    };

    let self = this;
    this.uploader = new this.FileUploader({
      queueLimit: 1,
      formData: [
        {
          key: 'value'
        }
      ]
    });

    this.uploader.onAfterAddingFile = file => {

      self.ficheiroLido = false;

      let fileReader = new FileReader();

      fileReader.onload = () => {
        self.ficheiroLido = true;
        self.text = fileReader.result;
        // Split text by ";"
        self.newText = self.text.split(";");

        console.log(self.newText);

        //Diario de Coimbra e Leiria
        if (self.newText[47] === 'Morada' && self.newText[48] === 'Número assinante' && self.newText[49] === 'Cod postal') {
          console.log("certo DC e DL");
          let parcelasDC = 24;

          // Parse for \n that breaks line
          let newText = self.text.split(';');
          let aux = [];

          for (let i = 0; i < newText.length; i++) {
            if (i % (parcelasDC - 1) !== 0)
              aux.push(newText[i]);
            else {
              let last = newText[i].split('\n');
              if (last.length === 1)
                aux.push(newText[i]);
              else {
                console.log(last);
                let newlineContent = last.pop();
                aux.push(last.join(''));
                aux.push(newlineContent);
              }
            }
          }
          let assinantes = _.chunk(aux, parcelasDC);
          assinantes.splice(0,4);
          console.log("Assinantes", assinantes);
        }



        //Diario de Viseu
        else if (self.newText[29] === 'Morada' && self.newText[30] === 'Número assinante' && self.newText[31] === 'Cod postal') {
          console.log("certo DV");
          for (let i = 0; i < 56; i++) {
            self.newText.shift();
          }
          for (let j = 0; j < 15; j++) {

          }
        }
        //Diario de Aveiro
        else if (self.newText[37] === 'Ncontrato' && self.newText[38] === 'Vendnm' && self.newText[39] === 'Obs') {
          console.log("certo DA");
          for (let i = 0; i < 72; i++) {
            self.newText.shift();
          }
          for (let j = 0; j < 19; j++) {

          }
        } else {
          console.log("Erro");
        }


        // self.newText = self.text.split(/[\n\r]+/);
        // self.newText.pop();
        //self.assinantes = [];
        //
        // self.newText.forEach(line => {
        //   self.assinantes.push(line.split(';'));
        // });

        //Diário de Coimbra e outros
        // if (self.assinantes[2][0] === 'Nome') {
        //   this.jornal = 1;
        //   //Diário de Aveiro
        // } else if (self.assinantes[2][0] === 'Ncont') {
        //   this.jornal = 2;
        //   //Se não tiver o formato correto
        // } else {
        //   this.jornal = 3;
        // }
        //
        // console.log("self.assinantes", self.assinantes);
        // console.log("self.assinantes", self.assinantes[2][0]);
        // console.log(this.jornal);

        //   this.table = new this.NgTableParams({
        //     count: 20
        //   }, {
        //     dataset: this.assinantes
        //   });
      };
      fileReader.readAsText(file._file, 'ISO-8859-1');
    };
  };
};

SincronizacaoPHCController.$inject = ['FileUploader', 'Domains', '$filter', 'UIService', 'NgTableParams'];
