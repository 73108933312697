export function routes($stateProvider) {
  $stateProvider
    .state('app.teasers', {
      template: '<ui-view></ui-view>',
      url: '/teasers',
      abstract: true
    })
    .state('app.teasers.list', {
      url: '/',
      template: require('./directory/directory.view.html'),
      controller: 'NewsletterListController',
      controllerAs: 'vm',
      title: 'Teasers'
    })
    .state('app.newsletter.add', {
      url: '/new',
      template: require('./new/new.view.html'),
      controller: 'NewsletterInsertController',
      controllerAs: 'vm',
      title: 'Novo Teaser'
    })
    .state('app.dominios', {
      template: '<ui-view></ui-view>',
      url: '/administration/dominios',
      abstract: true
    })
    .state('app.dominios.list', {
      template: require('./dominios/dominios.view.html'),
      url: '/',
      title: 'Dominios',
      controller: 'DominioController',
      controllerAs: 'vm',
    })
    .state('app.dominios.details', {
      template: require('./dominios/detalhesDominio/detalhesdominio.view.html'),
      url: '/{id}',
      controller: 'DetalhesDominioController',
      controllerAs: 'vm',
    })
    .state('app.webpages', {
      template: '<ui-view></ui-view>',
      url: '/paginas',
      abstract: true
    })
    .state('app.webpages.list', {
      role: 'pagesList',
      template: require('./webpages/webpage.view.html'),
      url: '?{page:int}&{items:int}&order&sort',
      title: 'Webpages',
      controller: 'WebpagesListController',
      controllerAs: 'vm',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
      }
    })
    .state('app.webpages.details', {
      role: 'pagesList',
      template: require('./webpages/details/detalhesPagina.view.html'),
      url: '/{id}',
      controller: 'DetalhesWebpagesController',
      controllerAs: 'vm',
    })
    .state('app.publicidade', {
      template: '<ui-view></ui-view>',
      url: '/publicidade',
      abstract: true
    })
    .state('app.publicidade.list', {
      role: 'pubList',
      template: require('./publicidade/publicidade.view.html'),
      url: '/',
      title: 'Publicidade',
      controller: 'PublicidadeController',
      controllerAs: 'vm',
    })
    .state('app.publicidade.add', {
      role: 'pubAdd',
      template: require('./publicidade/new/newPublicidade.view.html'),
      title: 'Adicionar Publicidade',
      url: '/new',
      controller: 'NewPublicidadeController',
      controllerAs: 'vm',
    })
    .state('app.publicidade.details', {
      role: 'pubList',
      template: require('./publicidade/detalhesPublicidade/detalhesPublicidade.view.html'),
      url: '/{id}',
      controller: 'DetalhesPublicidadeController',
      controllerAs: 'vm',
    })
    .state('app.newsletters', {
      template: '<ui-view></ui-view>',
      url: '/newsletters',
      abstract: true
    })
    .state('app.newsletters.list', {
      template: require('./newsletters/newsletter.view.html'),
      url: '?{page:int}&{items:int}&order&sort',
      title: 'Newsletters',
      controller: 'ListNewsletterController',
      controllerAs: 'vm',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
      }
    })
    .state('app.newsletters.details', {
      template: require('./newsletters/detailsNewsletter/detailsNewsletter.view.html'),
      url: '/{id}',
      controller: 'DetalhesNewsletterController',
      controllerAs: 'vm',
    })
    .state('app.noticias.newNewsletter', {
      url: '/enviarNewsletter',
      params: {selected: null, edit: null},
      controller: 'NewsletterController',
      title: 'Guardar Newsletter',
      controllerAs: 'vm',
      template: require('./noticias/envioNewsletter/envioNewsletter.view.html'),
    })
    .state('app.noticias.newNewsletter.1', {
      template: require('./noticias/envioNewsletter/informacaoNewsletter.view.html')
    })
    .state('app.noticias.newNewsletter.2', {
      template: require('./noticias/envioNewsletter/previewNewsletter.view.html')
    })
    .state('app.noticias.newNewsletter.3', {
      template: require('./noticias/envioNewsletter/agendamentoNewsletter.view.html')
    })
    .state('app.modalidades', {
      template: '<ui-view></ui-view>',
      url: '/administration/modalidades',
      abstract: true
    })
    .state('app.modalidades.list', {
      role: 'subscriptionList',
      template: require('./modalidades/modalidades.view.html'),
      url: '/',
      title: 'Modalidades',
      controller: 'ModalidadesController',
      controllerAs: 'vm',
    })
    .state('app.modalidades.details', {
      role: 'subscriptionList',
      template: require('./modalidades/detalhesModalidade/detalhesModalidade.view.html'),
      url: '/{id}',
      controller: 'DetalhesModalidadesController',
      controllerAs: 'vm',
    })
    .state('app.clients', {
      template: '<ui-view></ui-view>',
      url: '/clientes',
      abstract: true
    })
    .state('app.clients.list', {
      role: 'clientsList',
      template: require('./clientes/list/cliente.view.html'),
      url: '?{page:int}&{items:int}&order&sort',
      title: 'Clientes',
      controller: 'ClientesController',
      controllerAs: 'vm',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "name",
          dynamic: true
        },
        sort: {
          value: "asc",
          dynamic: true
        },
        filter: {
          dynamic: true
        },
      }
    })
    .state('app.clients.details', {
      role: 'clientsList',
      template: require('./clientes/details/clienteDetalhes.view.html'),
      url: '/{id}',
      title: 'Detalhes de Cliente',
      controller: 'ClientesDetalhesController',
      controllerAs: 'vm',
    });
};

routes.$inject = ['$stateProvider'];
