// Use this to preview pages from PDF
import pdfjsLib from 'pdfjs-dist';

export default class NewEditionController {
  constructor(UIService, AuthenticationService, AuthorizationService, FileUploader, Issues, Timelines, Magazines, $timeout, $state, NgTableParams) {
    this.loaded = false;
    this.form = {};
    this.edicao = {};
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.FileUploader = FileUploader;
    this.NgTableParams = NgTableParams;
    this.UI = UIService;
    this.Issues = Issues;
    this.Timelines = Timelines;
    this.$timeout = $timeout;
    this.$state = $state;
    this.setupUploaders();
    this.auxMagazine = {};
    this.aux = {};
    this.getEdicao();

    Magazines.find({
      filter: {
        where: {
          id: {between: [1, 4]},
          domainId: {
            inq: AuthorizationService.getDomains()
          }
        },
        include: 'dominio'
      }
    }).$promise.then(res => {
      this.magazines = res;
      if (res.length === 1) {
        this.auxMagazine.selected = res[0];
      }
      this.loaded = true;
    });
  };

  getNews = () => {
    if (angular.isUndefined(this.edicao.dateIssued) || angular.isUndefined(this.auxMagazine.selected)) {
      return;
    } else {
      let wait = this.UI.showWaiting();
      this.Timelines.find({
        filter: {
          where: {
            featured: true,
            active: true,
            domainId: this.auxMagazine.selected.domainId,
            and: [
              {
                pubdate: {
                  gte: moment(this.edicao.dateIssued).startOf('d')
                }
              },
              {
                pubdate: {
                  lte: moment(this.edicao.dateIssued).endOf('d')
                }
              },
            ],
          },
          include: 'news'
        }
      }).$promise.then(publications => {
        this.timelines = publications;
        wait.dismiss();
        if (publications.length === 0)
          this.UI.addToast('Não existem notícias para o dia selecionado');
      }).catch(() => {
        wait.dismiss();
        this.UI.addToast('Erro ao carregar notícias para o dia selecionado');
      })
    }
  };

  getEdicao = () => {
    this.edicaoLoading = true;
    this.Issues.find({
      filter: {
        where: {
          active: 1
        },
        include: [{
          relation: 'magazines',
          scope: {
            where: {active: 1}
          }
        },
          {
            relation: 'dominio',
            scope: {
              where: {active: 1}
            }
          }]
      }
    }).$promise.then((res) => {
      this.edicao = res;
      this.edicaoLoading = false;
    }).catch((error) => {
      this.UI.addToast('Erro ao carregar edição');
      this.$state.go('app.edicoes.list');
    })
  };


  setupUploaders = () => {
    // Setup cover uploader
    this.coverUploader = new this.FileUploader({
      url: '/api/assets/upload/covers',
      queueLimit: 1,
      headers: {'Authorization': this.Auth.getToken()},
    });
    // Setup pdf uploader
    this.pdfUploader = new this.FileUploader({
      url: '/api/assets/upload/issues',
      queueLimit: 1,
      headers: {'Authorization': this.Auth.getToken()},
    });
    let pdfFilter = {
      name: 'verifyPDF',
      fn: function (item, options) {
        let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|'.indexOf(type) !== -1;
      }
    };
    let minSizeFilter = {
      name: 'fileGreaterZero',
      fn: function (item, options) {
        if (item.size <= 0) {
          this.UI.addToast('Erro ao obter tamanho do ficheiro, verifique se o mesmo não está corrompido');
        }
        return item.size > 0;
      }
    };

    this.pdfUploader.filters.push(pdfFilter);
    this.pdfUploader.filters.push(minSizeFilter);
    // Generate and preview cover after adding PDF
    this.pdfUploader.onAfterAddingFile = file => {
      this.edicao.size = file._file.size;
      this.imageLoading = true;
      let fileReader = new FileReader();
      fileReader.onload = (ev) => {
        pdfjsLib.getDocument(fileReader.result).promise.then((pdf) => {
          return pdf.getPage(1);
        }).then(page => {
          // Get viewport (dimensions)
          let viewport = page.getViewport(1.0);
          // Create canvas
          let canvas = document.createElement('canvas');
          // Fetch canvas' 2d context
          let context = canvas.getContext('2d');
          // Set dimensions to Canvas
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          // Prepare object needed by render method
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          // Render PDF page
          let dataURItoBlob = (dataURI) => {
            let byteString = atob(dataURI.toString().split(',')[1]);
            //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            //or mimeString if you want
            return new Blob([ab], {type: 'image/jpeg'});
          };
          let task = page.render(renderContext);
          task.promise.then(() => {
            let base64 = canvas.toDataURL('image/jpeg');
            this.$timeout(() => {
              this.imgPreview = base64;
              this.imageLoading = false;
            });
            let blob = dataURItoBlob(base64);
            const uuid = this.UUID();
            let name = `${uuid}.jpg`;
            this.edicao.cover = name;
            let file = new File([blob], name);
            // Create "dummy" file and add it to the uploader
            let dummy = new this.FileUploader.FileItem(this.coverUploader, {
              lastModifiedData: new Date(),
              type: 'image/jpeg',
              name: name
            });
            dummy._file = file;
            this.coverUploader.queue = [dummy];
          });

        });
      };
      fileReader.readAsArrayBuffer(file._file);
    };
    // Change name before upload
    this.pdfUploader.onBeforeUploadItem = (item) => {
      let name = this.UUID();
      item.file.name = `${name}.pdf`;
      this.edicao.pdf = item.file.name;
    };
    this.coverUploader.onSuccessItem = (item, response, status, headers) => {
      this.pdfUploader.uploadAll();
    };
    this.coverUploader.onErrorItem = () => {
      this.UI.addToast('Ocorreu um erro a carregar capa de edição');
    };
    // If file upload succeded, submit edition
    this.pdfUploader.onSuccessItem = (item, response, status, headers) => {
      let m = moment().utc();
      let year = m.format('YYYY');
      let month = m.format('MM');
      this.timelineOptions = self.timeline.news;
      this.timelineOption = {};
      this.Issues.create({
        id: 0,
        uuid: this.UUID(),
        title: this.edicao.dateIssued.format('DD/MM/YYYY'),
        dateIssued: this.edicao.dateIssued.hour(2).minute(0).second(0).millisecond(0).toISOString(),
        magazineId: this.auxMagazine.selected.id,
        domainId: this.auxMagazine.selected.domainId,
        appleCode: `${this.auxMagazine.selected.appleCode}.${this.edicao.dateIssued.format('DDMMYYYY')}`,
        price: 0.89,
        enabled: true,
        mancheteId: this.aux.selected.news.title,
        url: `/api/assets/download/issues/${year}/${month}/${this.edicao.pdf}`,
        urlCover: `/api/assets/download/covers/${year}/${month}/${this.edicao.cover}`,
        size: this.edicao.size,
        active: true
      }).$promise.then((data) => {
        this.UI.addToast('Edição criada com sucesso');
        this.$state.go('app.edicoes.list');
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a criar edição');
      });
    };
    // If file upload failed, submit edition
    this.pdfUploader.onErrorItem = () => {
      this.UI.addToast('Ocorreu um erro a carregar PDF');
    }
  };

  UUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-"
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };


  submit = () => {
    if (this.form.issue.$invalid) {
      this.UI.addToast('Existem erros de preenchimento do formulário');
    } else if (this.pdfUploader.queue.length === 1 && this.coverUploader.queue.length === 1) {
      this.coverUploader.uploadAll();
    } else {
      this.UI.addToast('Carregue a edição PDF antes de submeter formulário');
    }
  }
}

NewEditionController.$inject = ['UIService', 'AuthenticationService', 'AuthorizationService', 'FileUploader', 'Issues', 'Timelines', 'Magazines', '$timeout', '$state', 'NgTableParams'];
