export default class NotificationService {
  constructor(Notification, $q) {
    this.Notification = Notification;
    this.$q = $q;
  }

  list = filter => {
    let defer = this.$q.defer();
    this.Notification.count({
      where: filter.where
    }).$promise.then(r => {
      this.Notification.find({
        filter: filter
      }).$promise.then(list => {
        defer.resolve({
          total: r.count,
          data: list
        })
      }).catch(err => {
        defer.reject(err);
      });
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  }

  get = id => {
    let defer = this.$q.defer();
    this.Notification.findOne({
      filter: {
        where: {
          id: id
        },
        include: {
          relation: 'Timeline',
          scope: {
            where: {
              active: true,
            },
            include: {
              relation: 'news',
              scope: {
                where: {
                  active: true,
                },
                include: 'files'
              },
            }
          }
        },
      }
    }).$promise.then(r => {
      defer.resolve(r);
    }).catch(e => {
      defer.reject(e);
    });
    return defer.promise;
  }

  save = data => {
    let defer = this.$q.defer();
    data.active = true;
    this.Notification.upsert(data).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  }

  delete = data => {
    let defer = this.$q.defer();
    this.Notification.deleteById({
      id: data.id
    }).$promise.then(r => defer.resolve(data)).catch(e => defer.reject(e));
    return defer.promise;
  }
}

NotificationService.$inject = ['Notification', '$q'];
