export default class EdicoesViewController {
  constructor(Issues, Timelines, UIService, NgTableParams, $stateParams, FileUploader, $rootScope, $state, $filter, $http) {
    this.Issues = Issues;
    this.Timelines = Timelines;
    this.UI = UIService;
    this.id = $stateParams.id;
    this.edicaoLoading = true;
    this.NgTableParams = NgTableParams;
    this.FileUploader = FileUploader;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.getEdicaoDetalhes(this.id);

    this.sortable = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        this.edicaoLoading = true;
        for (let i = 0; i < this.timeline.length; i++) {
          let timeline = this.timeline[i];
          timeline.indexador = this.timeline.length - i;
          timeline.$save();
        }
        
        this.getTimelines();
        this.UI.addToast("Ordem atualizada");
      }
    };
  }

  viewCapa = () => {
    this.UI.showDialog({
      template: require('./cover.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.cover = this.edicao.urlCover;
      }]
    });
  }

  getTimelines = () => {
    this.Timelines.find({
      filter: {
        where: {
          domainId: this.edicao.magazines.domainId,
          pubdate: {
            between: [moment.utc(this.edicao.dateIssued), moment.utc(this.edicao.dateIssued).add(1, 'days')]
          },
          active: 1
        },
        include: {
          relation: 'news',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'files',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        },
        order: 'indexador DESC'
      }
    }).$promise.then((res) => {
      this.timeline = res;

      this.table = new this.NgTableParams({
        count: 15,
        sorting: {
          pubdate: 'desc'
        }
      }, {
        dataset: this.timeline
      });
      this.edicaoLoading = false;
    }).catch((error) => {
      this.edicaoLoading = false;
      console.log(error);
    })
  };

  getEdicaoDetalhes = (id) => {
    this.edicaoLoading = true;
    this.Issues.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: ['manchete', {
            relation: 'magazines',
            scope: {
              where: {
                active: 1
              }
            }
          },
          {
            relation: 'dominio',
            scope: {
              where: {
                active: 1
              }
            }
          }
        ]
      }
    }).$promise.then((res) => {
      this.edicao = res;
      this.getTimelines();
    }).catch((error) => {
      this.edicaoLoading = false;
      this.UI.addToast('Erro ao carregar edição');
      this.$state.go('app.edicoes.list');
    })
  };
}

EdicoesViewController.$inject = ['Issues', 'Timelines', 'UIService', 'NgTableParams', '$stateParams', 'FileUploader', '$rootScope', '$state', '$filter', '$http'];
