export default class DetalhesNoticiasController {
  constructor(Webpages, Domains, NgTableParams, $rootScope, $state, $stateParams, $filter, UIService, $http) {
    this.pagina = null;
    this.dominios = null;
    this.Webpages = Webpages;
    this.Domains = Domains;
    this.id = $stateParams.id;
    this.$http = $http;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.paginaLoading = true;
    this.getDetalhesPagina(this.id);
    this.getDominio();
  };

  getDetalhesPagina = (id) => {
    this.paginaLoading = true;
    this.Webpages.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: {
          relation: 'dominio',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.pagina = res;
      this.paginaLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getDominio = () => {
    this.paginaLoading = true;
    this.Domains.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.dominios = res;
      this.paginaLoading = false;

    }).catch((error) => {
      console.log(error);
    })
  };

  editarPagina = (m) => {
    let self = this;

    let options = {
      size: 'lg',
      template: require('./editarPagina.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.pagina = {};
        $scope.pagina.id = m.id;
        $scope.pagina.title = m.title;
        $scope.pagina.body = m.body;
        $scope.domainsOptions = self.dominios;
        $scope.domainsOption = m.dominio;

        $scope.label = "Editar Página";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);
    dialogEdit.then((a) => {
      this.Webpages.findOne({
        filter: {
          where: {
            id: a.pagina.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.title = a.pagina.title;
        res.id = a.pagina.id;
        res.body = a.pagina.body;
        res.domainId = a.domainsOption.id;
        res.$save().then((r) => {
          this.getDetalhesPagina(this.id);
          this.UI.addToast("Página editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da página!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

};

DetalhesNoticiasController.$inject = ['Webpages', 'Domains', 'NgTableParams', '$rootScope', '$state', '$stateParams', '$filter', 'UIService', '$http'];
