import angular from "angular";
export default angular.module('app.interface.table', [])
  .controller('pmdTableController', ['$scope', '$element', '$attrs', '$parse', function ($scope, $element, $attrs, $parse) {
    $scope.init = () => {
      console.log("OI!");
    };
  }])
  .directive('pmdTable', function () {
    return {
      require: '^ngModel',
      restrict: 'E',
      controller: 'pmdTableController',
      link: function (scope, element, attrs, ngModel, ctrl) {
        console.log(scope, element, attrs, ngModel, ctrl);
        ctrl.init();
      }
    };
  })
  .name;
