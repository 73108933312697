export default class ListNewsletterController {
  constructor(
    Newsletter,
    News,
    $http,
    NgTableParams,
    $rootScope,
    $state,
    $filter,
    UIService,
    $stateParams
  ) {
    this.newsletters = null;
    this.$http = $http;
    this.Newsletter = Newsletter;
    this.News = News;
    this.$state = $state;
    this.opt = $state.params;
    this.$stateParams = $stateParams;
    this.selected = $stateParams.selected;
    this.newslettersLoading = true;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$filter = $filter;
    this.getNewsletters();
  }

  editNewsletter = (n) => {
    this.$state.go("app.noticias.newNewsletter.1", { selected: n, edit: true, });
  };

  getNewsletters = () => {
    this.newslettersLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    let whereFilter = {
      active: 1,
    };
    this.Newsletter.count({
      fields: { id: true },
      where: whereFilter,
    })
      .$promise.then((res) => {
        this.total = res.count;
        this.Newsletter.find({
          filter: {
            fields: {
              observacoes: false,
            },
            where: whereFilter,
            order: order,
            limit: this.opt.items,
            skip: (this.opt.page - 1) * this.opt.items,
            include: [
              {
                relation: "newDestaque",
                scope: {
                  where: {
                    active: 1,
                  },
                  include: {
                    relation: "files",
                    scope: {
                      where: {
                        active: 1,
                      },
                    },
                  },
                },
              },
              {
                relation: "dominio",
                scope: {
                  where: {
                    active: 1,
                  },
                },
              },
              {
                relation: "list",
              },
              {
                relation: "message",
              },
              {
                relation: "news",
                scope: {
                  where: {
                    active: 1,
                  },
                  include: {
                    relation: "files",
                    scope: {
                      where: {
                        active: 1,
                      },
                    },
                  },
                },
              },
              {
                relation: "publicity",
                scope: {
                  where: {
                    active: 1,
                  },
                  include: {
                    relation: "files",
                    scope: {
                      where: {
                        active: 1,
                      },
                    },
                  },
                },
              },
            ],
          },
        })
          .$promise.then((newsletter) => {
            let page = this.opt.page;
            let items = this.opt.items;
            let total = this.total;
            this.start = (page - 1) * items + 1;
            // Text
            if (this.start - 1 + items + items >= total) {
              this.end = total;
            } else {
              this.end = Number.parseInt(this.start - 1 + items);
            }
            this.newsletters = newsletter;
            this.newsletters.forEach((p) => {
              if (this.opt.sort === "asc") {
                p.index = this.start + this.newsletters.indexOf(p);
              } else {
                p.index =
                  this.total - this.start - this.newsletters.indexOf(p) + 1;
              }
            });
            this.newslettersLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sort = (keyname) => {
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.newsletters.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getNewsletters();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.newsletters.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getNewsletters();
  };

  removerNewsletter = (a) => {
    this.Newsletter.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1,
        },
      },
    }).$promise.then((res) => {
      let options = {
        size: "md",
        template: require("./removerNewsletter.view.html"),
        controller: [
          "$scope",
          "$dialog",
          ($scope, $dialog) => {
            $scope.ok = () => {
              $dialog.close(res);
            };
            $scope.cancel = () => {
              $dialog.dismiss("cancel");
            };
          },
        ],
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove
        .then((result) => {
          result.active = 0;
          result
            .$save()
            .then((r) => {
              this.UI.addToast("Newsletter removida com sucesso!");
              this.getNewsletters();
            })
            .catch((error) => {
              console.log(error);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  enviarNewsletter = (a) => {
    let confirm = this.UI.showConfirm(
      "Deseja enviar a newsletter? Esta ação não pode ser cancelada."
    );
    confirm.then((res) => {
      if (res) {
        this.Newsletter.send({ id: a.id })
          .$promise.then((r) => {
            this.UI.addToast("Newsletter em processamento!");
            this.getNewsletters();
          })
          .catch((error) => {
            this.UI.addToast(error);
          });
      }
    });
  };
}

ListNewsletterController.$inject = [
  "Newsletter",
  "News",
  "$http",
  "NgTableParams",
  "$rootScope",
  "$state",
  "$filter",
  "UIService",
  "$stateParams",
];
