export function routes($stateProvider) {
  $stateProvider
    .state('app.noticias', {
      template: '<ui-view></ui-view>',
      url: '/noticias',
      abstract: true
    })
    .state('app.noticias.list', {
      url: '?{page:int}&{items:int}&order&sort&filter',
      title: 'Notícias',
      role: 'newsList',
      template: require('./list/list.view.html'),
      controller: 'NoticiasListController',
      controllerAs: 'vm',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "createDate",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        },
      }
    })
    .state('app.noticias.add', {
      template: require('./add/add.view.html'),
      title: 'Adicionar Notícia',
      role: 'newsAdd',
      url: '/adicionar',
      controller: 'NoticiasAddController',
      controllerAs: 'vm',
    })
    .state('app.noticias.edit', {
      role: 'newsEdit',
      template: require('./edit/edit.view.html'),
      title: 'Editar Notícia',
      url: '/{id}/editar',
      controller: 'NoticiasEditController',
      controllerAs: 'vm',
    })
    .state('app.noticias.details', {
      role: 'newsList',
      template: require('./view/view.view.html'),
      url: '/{id}',
      controller: 'NoticiasViewController',
      controllerAs: 'vm',
    })
}

routes.$inject = ['$stateProvider'];

