export default class NewsletterController {
  constructor(
    $q,
    $state,
    News,
    Publicitys,
    Newsletter,
    PhplistList,
    NewsletterHasNews,
    Domains,
    UIService,
    $stateParams,
    $filter
  ) {
    this.state = $state;
    this.$stateParams = $stateParams;
    this.Publicitys = Publicitys;
    this.PhplistList = PhplistList;

    console.log($stateParams);

    //this.outrasNotícias --> todas as notícias selecionadas excepto a notícia destaque
    if ($stateParams.selected == null) $state.go("app.noticias.list");
    else {
      //Se estiver a editar uma newsletter
      if (
        $stateParams.edit != null &&
        $state.$current.name === "app.noticias.newNewsletter.1"
      ) {
        this.outrasNoticias = _.filter(
          this.$stateParams.selected.news,
          (n) => n.id !== $stateParams.selected.newsDestaqueId
        );
        //this.getPubs();
        //$state.transitionTo('app.noticias.newNewsletter.', {selected: $stateParams.selected, edit: true});
      } else {
        //Se estiver a criar uma newsletter
        $state.transitionTo("app.noticias.newNewsletter.1", {
          selected: $stateParams.selected,
        });
      }
    }

    this.newsletterLoading = true;
    this.newsletter = null;
    this.UI = UIService;
    this.Newsletter = Newsletter;
    this.id = $stateParams.id;
    this.NewsletterHasNews = NewsletterHasNews;
    this.News = News;
    this.Domains = Domains;
    this.selected = $stateParams.selected;
    this.$q = $q;
    this.$filter = $filter;
    this.dominios = null;
    this.news = null;
    this.outrasNoticias = null;
    this.domain = { selected: null };
    this.publicity = { selected: null };
    this.list = { selected: null };
    this.newsDestaque = { selected: null };
    this.getNewsletter(this.id);
    this.getLists();
    this.getDominios();
    this.getNews();
    if (localStorage.getItem("newsletterForm")) {
      this.form = JSON.parse(localStorage.getItem("newsletterForm"));
    } else {
      this.form = {
        newsletter: {
          id: 0,
        },
        preview: {},
        agendamento: [],
        newsDestaque: null,
      };
    }

    // this.newsDestaque.selected --> Notícia destaque da newsletter selecionada
    // $stateParams.selected --> A newsletter que selecionei para editar
    if ($stateParams.edit === true) {
      this.form.newsletter.titulo = $stateParams.selected.titulo;
      this.form.newsletter.cabecalho = $stateParams.selected.cabecalho;
      this.form.newsletter.rodape = $stateParams.selected.rodape;
      this.form.newsletter.observacoes = $stateParams.selected.observacoes;
      this.form.newsletter.domain = $stateParams.selected.dominio;
      this.domain.selected = this.form.newsletter.domain;
      this.form.newsletter.publicity = $stateParams.selected.publicity;
      this.publicity.selected = this.form.newsletter.publicity;
      this.form.newsletter.list = $stateParams.selected.list;
      this.list.selected = this.form.newsletter.list;
      this.form.newsletter.enviada = $stateParams.selected.enviada;
      this.form.newsletter.dataenvio = $stateParams.selected.dataenvio;
      this.selected = $stateParams.selected.news;
      this.newsDestaque.selected = $stateParams.selected.newDestaque;
      if ($stateParams.outrasNoticias)
        this.outrasNoticias = $stateParams.outrasNoticias;
      this.getPubs();
    }
  }

  getLists = () => {
    this.PhplistList.find({
      filter: {
        where: {
          category: "Newsletter",
        },
      },
    }).$promise.then((r) => {
      this.lists = r;
    });
  };

  generatePreview = () => {
    let sendDate = moment();
    this.preview = {
      facebook: this.form.newsletter.domain.facebookUrl,
      twitter: this.form.newsletter.domain.twitterUrl,
      cabecalho: this.form.newsletter.cabecalho || null,
      rodape: this.form.newsletter.rodape || null,
      ano: moment().format("YYYY"),
      jornal: this.form.newsletter.domain.name,
      cor:
        this.form.newsletter.domain.id === 2
          ? "#0065A7"
          : this.form.newsletter.domain.id === 5
          ? "#3B7E95"
          : "#BA0304",
      titulo:
        this.form.newsletter.titulo ||
        `Newsletter ${sendDate.format("DD [de] MMMM [de] YYYY")}`,
      url: this.form.newsletter.domain.homeUrl,
      logo: `${this.form.newsletter.domain.homeUrl}${this.form.newsletter.domain.logoPath}`,
      destaque: {
        titulo: this.newDestaque.selected.title,
        id: this.newDestaque.selected.id,
        url: `${this.form.newsletter.domain.homeUrl}/noticia/${this.newDestaque.selected.id}`,
        imagem:
          this.newDestaque.selected.files.length > 0
            ? `${this.form.newsletter.domain.homeUrl}/${this.newDestaque.selected.files[0].path}`
            : "",
      },
      noticias: [],
    };
  };

  moveToState = (from, to) => {
    //Se for do passo 1 até ao passo 2
    if (from === 1 && to === 2) {
      this.$stateParams.selected.newsDestaqueId = this.newsDestaque.selected.id;
      this.$stateParams.selected.newDestaque = this.newsDestaque.selected.id;
    }
    //Criar uma nova newsletter
    if (this.$stateParams.edit === null) {
      this.outrasNoticias = _.filter(
        this.$stateParams.selected,
        (n) => n.id !== this.$stateParams.selected.newsDestaqueId
      );
      //Editar uma newsletter
    } else {
      this.outrasNoticias = _.filter(
        this.$stateParams.selected.news,
        (n) => n.id !== this.$stateParams.selected.newsDestaqueId
      );
    }

    this.state.transitionTo("app.noticias.newNewsletter." + to, {
      selected: this.$stateParams.selected,
      edit: this.$stateParams.edit,
    });
  };

  getDominios = () => {
    this.newsletterLoading = false;
    this.Domains.find({
      filter: {
        where: {
          active: 1,
        },
      },
    })
      .$promise.then((res) => {
        this.dominios = res;
        this.newsletterLoading = true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getNews = () => {
    this.News.find({
      filter: {
        fields: { id: true, title: true, resume: true, active: true },
        where: {
          active: 1,
        },
      },
    })
      .$promise.then((res) => {
        this.news = res;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPubs = () => {
    let now = moment();
    this.Publicitys.find({
      filter: {
        where: {
          section: 4,
          domainId: this.domain.selected.id,
          dateInit: {
            lte: now,
          },
        },
        include: "files",
      },
    }).$promise.then((r) => {
      this.pubs = r;
    });
  };

  getNewsletter = (id) => {
    this.newsletterLoading = false;
    this.Newsletter.find({
      filter: {
        where: {
          id: id,
          active: 1,
        },
        include: [
          "list",
          {
            relation: "dominio",
            scope: {
              where: {
                active: 1,
              },
            },
          },
        ],
      },
    })
      .$promise.then((res) => {
        this.newsletter = res;
        if (this.$stateParams.edit === true) {
          this.publicity.selected = res.publicity;
          this.form.newsletter.publicity = res.publicity;
          this.list.selected = res.list;
          this.form.newsletter.list = res.list;
          console.log(this.list);
        }
        //this.getPubs();

        this.newsletterLoading = true;
      })
      .catch((error) => {
        this.state.go("app.noticias.list");
        console.log(error);
      });
  };

  selectDomain = (item) => {
    this.getPubs();
    this.list.selected = this.dominios.find((r) => r.name.includes(item.name));
  };

  checkState = (num) => {
    return this.state.current.name === "app.noticias.newNewsletter." + num;
  };

  register = () => {
    let confirm = this.UI.showConfirm(
      "Tem a certeza que deseja guardar a Newsletter?"
    );
    let tasks = [];
    confirm.then((res) => {
      if (res) {
        this.form.newsletter.domain = this.domain;
        this.form.newsletter.list = this.list;
        this.form.newsletter.publicity = this.publicity;

        this.form.newsletter.newsDestaque = this.newsDestaque;

        localStorage.removeItem("newsletterForm");
        if (!this.$stateParams.edit) {
          // No Newsletter, create a new one
          this.Newsletter.create({
            id: 0,
            titulo: this.form.newsletter.titulo,
            cabecalho: this.form.newsletter.cabecalho,
            rodape: this.form.newsletter.rodape,
            observacoes: this.form.newsletter.observacoes,
            newsDestaqueId: this.form.newsletter.newsDestaque.selected.id,
            domainsId: this.form.newsletter.domain.selected.id,
            publicityId: this.form.newsletter.publicity.selected
              ? this.form.newsletter.publicity.selected.id
              : null,
            listId: this.form.newsletter.list.selected.id,
            enviada: this.form.newsletter.enviada,
            dataenvio: this.form.newsletter.dataenvio,
            active: 1,
          })
            .$promise.then((res) => {
              //Se a data de envio é a data tual então o campo "enviada" fica ativo
              if (res.dataenvio === moment()) {
                res.enviada = 1;
              }

              this.$stateParams.selected.forEach((noticia) => {
                let defer = this.$q.defer();
                this.NewsletterHasNews.create({
                  id: 0,
                  newId: noticia.id,
                  newsletterId: res.id,
                })
                  .$promise.then((res) => {
                    defer.resolve(res);
                  })
                  .catch((err) => {
                    console.log(err);
                    defer.reject(err);
                  });
                tasks.push(defer.promise);
              });
              this.$q
                .all(tasks)
                .then((res) => {
                  this.getNewsletter(this.id);
                  this.state.go("app.newsletters.list");
                  this.UI.addToast("Newsletter guardada com sucesso");
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              // Did not create newsletter
              console.log(err);
            });
        } else {
          // Editing Newsletter, update only
          this.Newsletter.findOne({
            filter: {
              where: {
                id: this.$stateParams.selected.id,
                active: 1,
              },
            },
          })
            .$promise.then((res) => {
              res.titulo = this.form.newsletter.titulo;
              res.cabecalho = this.form.newsletter.cabecalho;
              res.rodape = this.form.newsletter.rodape;
              res.observacoes = this.form.newsletter.observacoes;
              res.newsDestaqueId = this.form.newsletter.newsDestaque.selected.id;
              res.domainsId = this.form.newsletter.domain.selected.id;
              res.publicityId = this.form.newsletter.publicity.selected.id;
              res.listId = this.form.newsletter.list.selected.id;
              res.enviada = this.form.newsletter.enviada;
              res.dataenvio = this.form.newsletter.dataenvio;
              res
                .$save()
                .then((r) => {
                  console.log(
                    "vm.newsDestaque.selected",
                    this.newsDestaque.selected
                  );
                  this.getNewsletter(this.id);
                  this.UI.addToast("Newsletter editada com sucesso!");
                  this.state.go("app.newsletters.list");
                })
                .catch((err) => {
                  this.UI.addToast("Erro a editar newsletter!");
                  console.log(err);
                });
            })
            .catch((error) => {
              if (
                error !== "cancel" &&
                error !== "escape key press" &&
                error !== "backdrop click"
              )
                console.log(error);
            });
        }
      }
    });
  };

  createNew = () => {
    // If we don't have news to new, we don't want to open a modal to new news in the first place
    if (!this.news) {
      this.UI.addToast(
        "De momento não é possível adicionar notícias. Por favor tente mais tarde."
      );
      return;
    }
    let self = this;
    let news = angular.copy(this.news);
    let selected = this.$stateParams.selected.news
      ? angular.copy(this.$stateParams.selected.news)
      : [];

    news = _.differenceWith(news, selected, (a, b) => a.id === b.id);

    let options = {
      size: "lg",
      template: require("./modalAddNoticia.view.html"),
      controller: [
        "$scope",
        "$dialog",
        function ($scope, $dialog) {
          $scope.news = {};
          $scope.available = news;
          $scope.selected = selected;

          $scope.label = "Adicionar notícias";
          $scope.newFileName = "";
          // Search filter
          $scope.filter = "";
          // Pagination
          $scope.pagination = {
            perPage: 3,
            current: 0,
            previous: () => {
              if ($scope.pagination.current > 0) $scope.pagination.current--;
            },
            onFilter: () => {
              const filteredArray = self.$filter("filter")(
                $scope.available,
                $scope.filter
              );
              const afterFilter =
                Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
              if (afterFilter < $scope.pagination.current) {
                $scope.pagination.current = afterFilter;
              }
            },
            next: () => {
              if ($scope.pagination.current < $scope.pagination.total() - 1)
                $scope.pagination.current++;
            },
            total: () => {
              const filteredArray = self.$filter("filter")(
                $scope.available,
                $scope.filter
              );
              return Math.ceil(
                filteredArray.length / $scope.pagination.perPage
              );
            },
          };

          $scope.select = function (news) {
            news.oldIdx = $scope.available.indexOf(news);
            $scope.selected.push(news);
            $scope.available.splice(news.oldIdx, 1);
          };

          $scope.pop = function (news) {
            $scope.selected.splice($scope.selected.indexOf(news), 1);
            $scope.available.splice(news.oldIdx, 0, news);
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        },
      ],
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate
      .then((ok) => {
        this.newsletterLoading = true;
        let tasks = [];

        this.outrasNoticias.forEach((s) => {
          let defer = this.$q.defer();
          this.NewsletterHasNews.find({
            filter: {
              where: {
                and: [
                  { newId: s.id },
                  { newsletterId: this.$stateParams.selected.id },
                ],
              },
            },
          })
            .$promise.then((oldNews) => {
              if (oldNews.length === 0) defer.resolve(0);
              oldNews.forEach((op) => {
                this.NewsletterHasNews.deleteById({
                  id: op.id,
                })
                  .$promise.then(() => {
                    defer.resolve(op.id);
                  })
                  .catch((err) => {
                    console.log(err);
                    defer.reject(op.id);
                  });
              });
            })
            .catch((err) => {
              console.log(err);
            });
          tasks.push(defer.promise);
        });
        this.$q.all(tasks).then((res) => {
          let createTasks = [];
          ok.selected.forEach((s, index) => {
            let defer = this.$q.defer();
            this.NewsletterHasNews.create({
              id: 0,
              newId: s.id,
              newsletterId: this.$stateParams.selected.id,
            })
              .$promise.then((res) => {
                defer.resolve(s);
              })
              .catch((error) => {
                defer.reject(s);
              });
            createTasks.push(defer.promise);
          });
          this.$q
            .all(createTasks)
            .then((res) => {
              this.selected = ok.selected;
              this.UI.addToast(
                "Notícias adicionadas a esta newsletter com sucesso!"
              );
              this.getNewsletter(this.id);
            })
            .catch((err) => {
              this.UI.addToast(
                "Erro na adição de notícias. Por favor tente mais tarde."
              );
              this.newsletterLoading = false;
              console.log(err);
            });
        });
      })
      .catch((error) => {
        if (
          error !== "cancel" &&
          error !== "escape key press" &&
          error !== "backdrop click"
        )
          console.log(error);
      });
  };
}

NewsletterController.$inject = [
  "$q",
  "$state",
  "News",
  "Publicitys",
  "Newsletter",
  "PhplistList",
  "NewsletterHasNews",
  "Domains",
  "UIService",
  "$stateParams",
  "$filter",
];
