export default class EditorController {
  constructor($rootScope, $state, $stateParams, ChangelogService, UIService) {
    this.ChangelogService = ChangelogService;
    this.UI = UIService;
    let env = $rootScope.status.environment;
    this.previous = {};
    this.isEditing = null;

    if ($stateParams.major && $stateParams.minor && $stateParams.patch) {
      this.isEditing = true;
      this.ChangelogService.getVersion($stateParams.major, $stateParams.minor, $stateParams.patch).then((r) => {
        this.form = r;
        this.loaded = true;
      });
    } else {
      // Only save info of previous changelog if we are adding a new one
      this.isEditing = false;
      this.ChangelogService.getVersion().then((r) => {
        this.previous = r;
      });
      // Generate clean form
      this.form = {
        major: null,
        minor: null,
        patch: null,
        git: '',
        bugfixes: '',
        added: '',
        improvements: '',
        dev: ($rootScope.env === 'dev') ? true : false,
        staging: ($rootScope.env === 'staging') ? true : false,
        production: ($rootScope.env === 'production') ? true : false,
      };
    }
  }

  upsert = () => {
    this.ChangelogService.upsert(this.form).then(res => {
      this.form = res;
      this.UI.addToast('Inserido com sucesso');
    }).catch(err => {
      this.UI.addToast('Erro ao inserir changelog');
    });
  }
}

EditorController.$inject = ['$rootScope', '$state', '$stateParams', 'ChangelogService', 'UIService'];
