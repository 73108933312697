export default class LoggingController {
  constructor(Backlog, NgTableParams) {
    this.loaded = true;
    this.Backlog = Backlog;
    this.backlog = null;
    this.NgTableParams = NgTableParams;
    this.getBacklog();
  }

  getBacklog = () => {
    this.loaded = true;
    this.Backlog.find({
      filter: {
        order: 'date DESC',
        limit: 2000,
        include: {
          relation: 'user'
        }
      }
    }).$promise.then((res) => {
      console.log(res);
      this.backlog = res;

      this.table = new this.NgTableParams({
        count: 20,
        sorting: {
          date: 'desc'
        }
      }, {
        dataset: this.backlog
      });
      res.loaded = false;
    }).catch((error) => {
      console.log('Erro');
    })
  };

}

LoggingController.$inject = ['Backlog', 'NgTableParams'];
