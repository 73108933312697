export default class EdicoesDetailsController {
  constructor(Issues, Timelines, UIService, NgTableParams, $stateParams, FileUploader, $rootScope, $state, $filter, $http) {
    this.Issues = Issues;
    this.Timelines = Timelines;
    this.UI = UIService;
    this.id = $stateParams.id;
    this.edicaoLoading = true;
    this.NgTableParams = NgTableParams;
    this.FileUploader = FileUploader;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.getEdicaoDetalhes(this.id);
  };

  getTimelines = () => {
    this.Timelines.find({
      filter: {
        where: {
          domainId: this.edicao.magazines.domainId,
          pubdate: {between: [moment.utc(this.edicao.dateIssued), moment.utc(this.edicao.dateIssued).add(1, 'days')]},
          active: 1
        },
        include: {
          relation: 'news',
          scope: {
            where: {active: 1},
            include: {
              relation: 'files',
              scope: {
                where: {active: 1}
              }
            }
          }
        }
      }
    }).$promise.then((res) => {
      this.timeline = res;

      this.table = new this.NgTableParams({
        count: 15,
        sorting: {
          pubdate: 'desc'
        }
      }, {
        dataset: this.timeline
      });
      this.edicaoLoading = false;
    }).catch((error) => {
      this.edicaoLoading = false;
      console.log(error);
    })
  };

  getEdicaoDetalhes = (id) => {
    this.edicaoLoading = true;
    this.Issues.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: ['manchete', {
          relation: 'magazines',
          scope: {
            where: {active: 1}
          }
        },
          {
            relation: 'dominio',
            scope: {
              where: {active: 1}
            }
          }]
      }
    }).$promise.then((res) => {
      this.edicao = res;
      this.getTimelines();
    }).catch((error) => {
      this.edicaoLoading = false;
      this.UI.addToast('Erro ao carregar edição');
      this.$state.go('app.edicoes.list');
    })
  };
};

EdicoesDetailsController.$inject = ['Issues', 'Timelines', 'UIService', 'NgTableParams', '$stateParams', 'FileUploader', '$rootScope', '$state', '$filter', '$http'];



