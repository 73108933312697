export default class UIService {
  constructor($rootScope, $filter, Toast, Snackbar, Dialog) {
    this.$rootScope = $rootScope;
    $rootScope.status = {};
    this.$filter = $filter;
    this.Toast = Toast;
    this.Snackbar = Snackbar;
    this.Dialog = Dialog;
    this.sidebar = false;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.$rootScope.status = data;
    });
  }

  sidebarClick = () => {
    this.sidebar = !this.sidebar;
  };

  sidebarStatus = () => {
    return this.sidebar;
  };

  addToast = (message, length) => {
    this.Toast.add(message, length);
  };

  addToast = (message) => {
    this.Toast.add(message, this.Toast.LENGTH_SHORT);
  };

  addSnackbar = (message, action, dismissable) => {
    return this.Snackbar.add(message, action, dismissable);
  };

  showAlert = (message) => {
    return this.Dialog.alert(message);
  };

  showConfirm = (message) => {
    return this.Dialog.confirm(message);
  };

  showDialog = (options) => {
    return this.Dialog.dialog(options);
  };

  showWaiting = () => {
    return this.Dialog.waiting();
  };

  showDateRangePicker = (from, to, clear) => {
    clear = clear || false;
    console.log(clear);
    return this.Dialog.dateRange(from, to, clear);
  }
}

UIService.$inject = ['$rootScope', '$filter', 'Toast', 'Snackbar', 'Dialog'];
