 let md5 = require('md5');

export default class ClientesController {
  constructor(Clients, NgTableParams, UIService, $rootScope, $state, AuthorizationService, $timeout, $filter, $q, phcDCCl, ClientsMagazines) {
    this.Authorization = AuthorizationService;
    this.$timeout = $timeout;
    this.clients = null;
    this.UI = UIService;
    this.Clients = Clients;
    this.$q = $q;
    this.ClientsPHC = phcDCCl;
    this.ClientsMagazines = ClientsMagazines;
    this.clientsLoading = true;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.opt = $state.params;
    this.$state = $state;
    this.$filter = $filter;
    this.columns = [
      {
        id: 'name',
        name: 'Nome',
        type: 't',
      },
      {
        id: 'nif',
        name: 'NIF',
        type: 'n',
      },
      {
        id: 'email',
        name: 'Email',
        type: 't',
      }
    ];
    this.filters = [
      {
        val: 'a',
        name: 'Igual',
        type: 't',
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 't',
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 't',
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 't',
      },
      {
        val: 'e',
        name: 'Contém',
        type: 't',
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'n',
      },
      {
        val: 'b',
        name: 'Diferente (≠)',
        type: 'n',
      },
      {
        val: 'c',
        name: 'Maior (>)',
        type: 'n',
      },
      {
        val: 'd',
        name: 'Menor (<)',
        type: 'n',
      },
      {
        val: 'e',
        name: 'Maior ou igual (≥)',
        type: 'n',
      },
      {
        val: 'f',
        name: 'Menor ou igual (≤)',
        type: 'n',
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's',
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's',
      }
    ];
    this.customFilters = [];
    if (this.opt.filter) {
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              filterBy: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            data.oldColumn = angular.copy(data.column.selected);
            if (data.column.selected.type === 'd') {
              let d = a[2].split("-");
              if (d.length !== 2) {
                throw Error();
              }
              let from = moment(d[0], "DD/MM/YYYY").startOf('d');
              let to = moment(d[1], "DD/MM/YYYY").endOf('d');
              data.filterBy.selected = {type: 'd'};
              data.value = {from: from, to: to};
              data.dr = `${from.format('DD/MM/YYYY')} - ${to.format('DD/MM/YYYY')}`;
            } else {
              data.filterBy.selected = this.filters.find(f => {
                return f.val === a[1] && f.type === data.column.selected.type;
              });
              if (angular.isUndefined(data.filterBy.selected)) {
                throw Error();
              }
              data.oldFilter = angular.copy(data.filterBy.selected);
              if (data.filterBy.selected.type === 's') {
                data.value.selected = data.column.selected.values.find(f => {
                  // Double compare only to ignore strict type check
                  return f.id == a[2];
                });
                if (angular.isUndefined(data.value.selected)) {
                  throw Error();
                }
              } else {
                data.value = a[2];
              }
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
            this.applyFilter();
          }
        }
      });
    }
    this.getClients();
  };

  syncFromPHC = () => {
    let wait = this.UI.showWaiting();
    this.ClientsPHC.active().$promise.then(res => {
      wait.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./search.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.list = res;
          $scope.data = {};

          $scope.ok = () => $scope.$close($scope.data.selected);

          $scope.cancel = () => {
            $scope.$dismiss('cancel');
          }
        }]
      }).then(data => {
        console.log(data);
        if (data) {
          this.Clients.find({
            filter: {
              where: {
                email: data.assinante.email
              }

            }
          }).$promise.then(client => {
            if (client.length > 0) {
              this.ClientsMagazines.find({
                filter: {
                  where: {
                    clientId: client[0].id,
                    magazineId: 1 //TODO: Mudar isto
                  }
                }
              }).$promise.then(sig => {
                if (sig.length > 0) {
                  if (data.assinatura.estado === 1) {
                    // Atualiza assinatura com info do PHC
                    let date = moment(data.assinatura.dataRenovacao);
                    if (data.assinatura.pagamento === 'Débito Directo')
                      date.add(30, 'd');
                    if (moment(sig[0].end).isBefore(moment(date))) {
                      sig[0].end = date;
                      sig[0].dataSincronizacaoPhc = new Date();
                      sig[0].$save().then(() => {
                        this.UI.addToast("Assinatura atualizada com sucesso!");
                      });
                    } else {
                      this.UI.addToast("Assinatura já atualizada!");
                    }
                  } else {
                    // Cancela assinatura atual
                    sig[0].active = 0;
                    sig[0].dataSincronizacaoPhc = new Date();
                    sig[0].$save().then(() => {
                      this.UI.addToast("Assinatura cancelada com sucesso!");
                    })
                  }
                } else {
                  let date = moment(data.assinatura.dataRenovacao);
                  if (data.assinatura.pagamento === 'Débito Directo')
                    date.add(30, 'd');
                  if (data.assinatura.estado === 1) {
                    // Adiciona assinatura
                    this.ClientsMagazines.create({
                      id: 0,
                      clientId: client[0].id,
                      magazineId: 1, //TODO: Change
                      modalidadesId: 7,
                      moderadorId: null,
                      start: data.assinatura.data,
                      end: date,
                      comments: 'PHC',
                      metodoPagamento: 'Assinatura papel',
                      gdpr: 1,
                      active: 1,
                      dataSincronizacaoPhc: new Date()
                    }).$promise.then((res) => {
                      this.UI.addToast("Assinatura carregada com sucesso!");
                    });
                  }
                }
              })
            } else {
              if (data.assinatura.estado === 1) {
                // Create client here
                let cp = data.assinante.codPostal.replace(/\D/g, '');
                if (cp.length === 7) {
                  cp = `${cp.substring(0, 4)}-${cp.slice(-3)}`;
                } else {
                  cp = `${cp.substring(0, 4)}`;
                }
                this.Clients.create({
                  id: 0,
                  nif: data.assinante.nif.replace(/\s+/g, '') || "000000000",
                  password: md5(data.assinante.telefone.replace(/\s+/g, '')),
                  email: data.assinante.email,
                  name: data.assinante.nome,
                  maxDevices: 2,
                  approved: true,
                  dateRegistration: data.assinatura.data,
                  morada: data.assinante.morada,
                  codigoPostal: cp || null,
                  localidade: "",
                  rgpd: true,
                  active: true
                }).$promise.then(newClient => {
                  let date = moment(data.assinatura.dataRenovacao);
                  if (data.assinatura.pagamento === 'Débito Directo')
                    date.add(30, 'd');
                  this.ClientsMagazines.create({
                    id: 0,
                    clientId: newClient.id,
                    magazineId: 1, //TODO: Change
                    modalidadesId: 7,
                    moderadorId: null,
                    start: data.assinatura.data,
                    end: date,
                    comments: 'PHC',
                    metodoPagamento: 'Assinatura papel',
                    gdpr: 1,
                    active: 1,
                    dataSincronizacaoPhc: new Date()
                  }).$promise.then((cl) => {
                    this.UI.addToast("Cliente carregado com sucesso!");
                    this.ClientsMagazines.create({
                      id: 0,
                      clientId: cl.id,
                      magazineId: 1, //TODO: Change
                      modalidadesId: 7,
                      moderadorId: null,
                      start: data.assinatura.data,
                      end: date,
                      comments: 'PHC',
                      metodoPagamento: 'Assinatura papel',
                      gdpr: 1,
                      active: 1,
                      dataSincronizacaoPhc: new Date()
                    }).$promise.then(() => {
                      this.UI.addToast("Assinatura carregada com sucesso!");
                    });
                  })
                });
              }
            }
          });
        }
      })
    });
  };

  hasSelect = () => {
    return _.some(this.clients, function (r) {
      return r.selected === true;
    });
  };

  selectItem = () => {
    this.selected = _.filter(this.clients, function (r) {
      return r.selected === true;
    }).length;
  };

  showIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty('column'))
        return filter.column.selected.type === 'd';
      return false;

    }
    return false;
  };

  hideIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty('column'))
        return filter.column.selected.type !== 'd';
      return false;

    }
    return false;
  };

  oldColumn = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty('oldColumn')) {
        if (row.oldColumn.type !== item.type) {
          row.filterBy = undefined;
          row.value = undefined;
        }
      }
      if (row)
        row.oldColumn = angular.copy(item);
      if (item.type === 'd') {
        row.filterBy = {selected: {type: 'd', value: 'a'}};
      }
    })
  };

  showDateRangePicker = filter => {
    let from = undefined;
    let to = undefined;
    if (filter.hasOwnProperty(filter.value)) {
      from = filter.value.from;
      to = filter.value.to;
    }
    this.UI.showDateRangePicker(from, to).then(a => {
      if (a) {
        filter.dr = `${a.from.format('DD/MM/YYYY')} - ${a.to.format('DD/MM/YYYY')}`;
        filter.value = a;
      }
    });
  };

  oldFilter = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty('oldFilter')) {
        if (row.oldFilter.type !== item.type) {
          row.value = undefined;
        } else {
          if (item.type === 'd') {
            switch (item.val) {
              case 'a':
                row.value = moment.utc();
                // Today:
                break;
              case 'b':
                row.value = moment.utc();
                // Ontem
                break;
              case 'c':
                // Últimos 7 dias
                break;
              case 'd':
                // Personalizado
                break;
            }
          }
        }
      }
      if (row)
        row.oldFilter = angular.copy(item);
    });
  };

  applyFilter = () => {
    if (this.customFilters.length === 0) {
      // Remove filters
      this.fs = false;
      this.opt.page = 1;
      this.opt.filter = undefined;
      this.$state.go('app.clients.list', this.opt, {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: 'replace',
        // inherit the current params on the url
        inherit: true
      });
      this.getClients();
    } else {
      let string = "";
      let keepGoing = true;
      this.customFilters.forEach(filter => {
        if (filter.hasOwnProperty('column') && filter.hasOwnProperty('filterBy') && filter.hasOwnProperty('value')) {
          if (filter.column.hasOwnProperty('selected') && filter.filterBy.hasOwnProperty('selected')) {
            if (filter.filterBy.selected.type === 's' && !filter.value.hasOwnProperty('selected')) {
              keepGoing = false;
              return;
            }
            string += `${filter.column.selected.id}`;
            if (filter.column.selected.type === 'd') {
              string += `·a·${filter.value.from.format('DD/MM/YYYY')}-${filter.value.to.format('DD/MM/YYYY')}`;
            } else {
              string += `·${filter.filterBy.selected.val}.${filter.value.hasOwnProperty('selected') ? filter.value.selected.id : filter.value}${this.customFilters.indexOf(filter) === this.customFilters.length - 1 ? '' : ':'}`;
            }
          } else {
            keepGoing = false;
            return;
          }
        } else {
          keepGoing = false;
          return;
        }
      });
      if (!keepGoing) {
        return;
      }
      if (!angular.equals(string, "")) {
        this.opt.page = 1;
        this.opt.filter = string;
        this.$state.go('app.clients.list', this.opt, {
          // prevent the events onStart and onSuccess from firing
          notify: false,
          // prevent reload of the current state
          reload: false,
          // replace the last record when changing the params so you don't hit the back button and get old params
          location: 'replace',
          // inherit the current params on the url
          inherit: true
        });
      }
      this.fs = false;
      this.getClients();
    }
  };


  getClients = () => {
    this.clientsLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    let whereFilter = {
      active: 1
    };
    if (angular.isDefined(this.opt.filter)) {
      this.customFilters.forEach(filter => {
        let col = filter.column.selected.id;
        let query;
        // Handle type TEXT
        if (filter.column.selected.type === 't') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              // Texto igual
              query = filter.value;
              break;
            case 'b':
              // Texto diferente
              query = {
                neq: filter.value
              };
              break;
            case 'c':
              // Texto começa por
              query = {
                like: `${filter.value}%`
              };
              break;
            case 'd':
              // Texto termina com
              query = {
                like: `%${filter.value}`
              };
              break;
            case 'e':
              // Texto contém
              query = {
                like: `%${filter.value}%`
              };
              break;
            default:
              break;
          }
        }
        // Handle type NUMBER
        else if (filter.column.selected.type === 'n') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              // Número igual
              query = filter.value;
              break;
            case 'b':
              // Número não igual
              query = {
                neq: filter.value
              };
              break;
            case 'c':
              // Número maior que
              query = {
                gt: filter.value
              };
              break;
            case 'd':
              // Número menor que
              query = {
                lt: filter.value
              };
              break;
            case 'e':
              // Número maior ou igual a
              query = {
                gte: filter.value
              };
              break;
            case 'f':
              // Número menor ou igual a
              query = {
                lte: filter.value
              };
              break;
            default:
              break;
          }
        }
        // Handle type SELECT
        else if (filter.column.selected.type === 's') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              query = filter.value.selected.id;
              break;
            case 'b':
              query = {
                neq: filter.value.selected.id
              };
              break;
            default:
              break;
          }
        }
        // Handle date
        if (filter.column.selected.type === 'd') {
          if (filter.value.from.isSame(filter.value.to, 'd')) {
            query = filter.value.from.format('YYYY-MM-DD');
          }
          query = {between: [filter.value.from, filter.value.to]};
        }
        // Same column filter

        let a = {};
        a[col] = query;
        if (!whereFilter.hasOwnProperty('and')) {
          whereFilter.and = [];
        }
        whereFilter.and.push(a);
      });
    }
    this.Clients.count({
      fields: {id: true},
      where: whereFilter
    }).$promise.then((res) => {
      this.total = res.count;
      this.Clients.find({
        filter: {
          fields: {
            password: false,
            maxDevices: false,
            dateRegistration: false,
            morada: false,
            codigoPostal: false,
            localidade: false,
            rgpd: false
          },
          where: whereFilter,
          order: order,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
          include: {
            relation: 'CliMagazineRel',
            scope: {
              where: {active: 1}
            }
          }
        }
      }).$promise.then(client => {
        let page = this.opt.page;
        let items = this.opt.items;
        let total = this.total;
        this.start = (page - 1) * items + 1;
        // Text
        if ((this.start - 1 + items + items) >= total) {
          this.end = total;
        } else {
          this.end = Number.parseInt(this.start - 1 + items);
        }
        this.clients = client;
        this.clients.forEach(p => {
          if (this.opt.sort === 'asc') {
            p.index = this.start + this.clients.indexOf(p);
          } else {
            p.index = this.total - this.start - this.clients.indexOf(p) + 1;
          }
        });
        this.clientsLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  canFilter = (column) => {
    if (column.type === 't' || column.type === 'n') {
      return true;
    } else {
      return this.customFilters.find((f) => {
        if (f.hasOwnProperty('column')) {
          return f.column.selected.type === column.type;
        } else {
          return false;
        }

      }) === undefined;
    }
    return true;
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClients();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClients();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClients();
  };

  createClient = () => {
    let options = {
      size: 'lg',
      template: require('./addCliente.view.html'),
      controller: ['$scope', '$dialog', 'Clients', function ($scope, $dialog, Clients) {
        $scope.label = "Adicionar Cliente";

        $scope.Clients = Clients;

        $scope.data = {
          dgert: false
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.Clients.create({
        id: 0,
        name: ok.clients.name,
        email: ok.clients.email,
        nif: ok.clients.nif,
        telephone: ok.clients.telephone,
        dateRegistration: ok.clients.dateRegistration,
        morada: ok.clients.morada,
        codigoPostal: ok.clients.codigoPostal,
        localidade: ok.clients.localidade,
        active: 1,
        maxDevices: 2,
        rgpd: 1
      }).$promise.then((res) => {
        this.getClients(this.id);
        this.UI.addToast("Cliente adicionado com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação do cliente!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  removeCliente = (a) => {
    this.Clients.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./removeCliente.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Cliente removido com sucesso!");
          this.getClients(this.id);
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };


  editCliente = (m) => {
    let options = {
      size: 'lg',
      template: require('./addCliente.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.clients = {};
        $scope.clients.id = m.id;
        $scope.clients.name = m.name;
        $scope.clients.email = m.email;
        $scope.clients.nif = m.nif;
        $scope.clients.telephone = m.telephone;
        $scope.clients.dateRegistration = m.dateRegistration;
        $scope.clients.morada = m.morada;
        $scope.clients.localidade = m.localidade;
        $scope.clients.codigoPostal = m.codigoPostal;

        $scope.label = "Editar cliente";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Clients.findOne({
        filter: {
          where: {
            id: a.clients.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.id = a.clients.id;
        res.name = a.clients.name;
        res.email = a.clients.email;
        res.nif = a.clients.nif;
        res.telephone = a.clients.telephone;
        res.dateRegistration = a.clients.dateRegistration;
        res.morada = a.clients.morada;
        res.localidade = a.clients.localidade;
        res.codigoPostal = a.clients.codigoPostal;
        res.$save().then((r) => {
          this.getClients();
          this.UI.addToast("Cliente editado com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição do cliente!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };
};

ClientesController.$inject = ['Clients', 'NgTableParams', 'UIService', '$rootScope', '$state', 'AuthorizationService', '$timeout', '$filter', '$q', 'PhcDCCl', 'ClientsMagazines'];
