export default class ChangelogService {
  constructor($rootScope, $http, $q, Changelog, UIService, Authorization) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.Changelog = Changelog;
    this.Authorization = Authorization;
    this.UI = UIService;
    this.data = [];
    this.status = $rootScope.status;
    this.$http = $http;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
      $rootScope.status = data;
    });

  }

  loadData = () => {
    let defer = this.$q.defer();
    let apiQuery = {};

    apiQuery.order = 'partsum DESC';

    this.$http.get('https://apps.streamline.pt/api/Apps/191/changelogs?filter=' + JSON.stringify(apiQuery)).then((res) => {
      if (res.data === null) {
        defer.reject("Error");
      }

      res.data.forEach(r => {
        r.string = r.major + "." + r.minor + "." + r.patch;
      });
      this.data = res.data;
      defer.resolve(res.data);
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  };

  getVersion = (major, minor, patch) => {
    let found = null;

    let defer = this.$q.defer();
    this.loadData().then((res) => {
      if (angular.isUndefined(major) || angular.isUndefined(minor) || angular.isUndefined(patch)) {
        found = res[0];
      } else {
        res.forEach(r => {
          if (r.major == major && r.minor == minor && r.patch == patch) {
            found = r;
          }
        });
      }
      defer.resolve(found);
    });

    return defer.promise;
  };

  getVersions = () => {
    let defer = this.$q.defer();
    this.loadData().then(r => defer.resolve(r)).catch(err => defer.reject(err));
    return defer.promise;
  };
}

ChangelogService.$inject = ['$rootScope', '$http', '$q', 'Changelog', 'UIService', 'AuthorizationService'];
