import angular from 'angular';
import modalHelper from "./modalHelper";

export default angular.module('app.interface.dialogs', [modalHelper])
  .controller('DialogController', ['$scope', '$dialog', 'message', function ($scope, $dialog, message) {

    $scope.message = message;

    $scope.ok = () => {
      $dialog.close(true);
    };

    $scope.cancel = () => {
      $dialog.dismiss('cancel');
    };

  }])
  .factory('Dialog', ['$rootScope', '$modal', function ($rootScope, $modal) {
    return {
      waiting: () => {
        return $modal.open({
          keyboard: false,
          backdrop: 'static',
          size: 'sm',
          template: '<div style="padding: 24px 16px 16px 16px;"><pmd-spinner></pmd-spinner></div>'
        });
      },
      alert: message => {
        return $modal.open({
          keyboard: true,
          backdrop: 'static',
          template: require('./templates/alert.html'),
          controller: 'DialogController',
          resolve: {
            message: function () {
              return message;
            }
          }
        }).result;
      },
      confirm: (message) => {
        return $modal.open({
          keyboard: true,
          backdrop: 'static',
          template: require('./templates/confirm.html'),
          controller: 'DialogController',
          resolve: {
            message: function () {
              return message;
            }
          }
        }).result;
      },
      dialog: options => {
        return $modal.open(options).result;
      },
      dateRange: (from, to, clearDate) => {
        return $modal.open({
          size: 'dr-picker',
          backdrop: 'static',
          template: require('./templates/range.html'),
          controller: ['$scope', '$dialog', '$window', ($scope, $dialog, $window) => {

            // Initial sizing
            let width = $window.screen.availWidth;
            if (width < 768) {
              $scope.windowMd = false;
              $scope.flexProp = 'column';
            } else {
              $scope.windowMd = true;
              $scope.flexProp = 'row';
            }

            angular.element($window).bind('resize', function () {
              // Handle resize
              let width = $window.screen.availWidth;
              if (width < 768) {
                $scope.windowMd = false;
                $scope.flexProp = 'column';
              } else {
                $scope.windowMd = true;
                $scope.flexProp = 'row';
              }
              $scope.$digest();
            });

            if (from instanceof moment) {
              $scope.from = from.startOf('d').hour(1);
            } else {
              $scope.from = moment().startOf('d');
            }

            if (to instanceof moment) {
              $scope.to = to.endOf('d');
            } else {
              $scope.to = moment().endOf('d');
            }
            //OPTIONS

            $scope.fromOptions = {
              defaultDate: $scope.from.format('YYYY-MM-DD'),
              format: 'YYYY-MM-DD',
              inline: true,
              keepOpen: true,
            };

            $scope.toOptions = {
              defaultDate: $scope.to.format('YYYY-MM-DD'),
              format: 'YYYY-MM-DD',
              inline: true,
              keepOpen: true,
            };

            if (!clearDate) {
              $scope.fromOptions.maxDate = moment().endOf('d');
              $scope.toOptions.maxDate = moment().endOf('d');
            }

            $scope.ok = () => {
              $dialog.close({
                from: $scope.from.startOf('d'),
                to: $scope.to.endOf('d')
              });
            };

            $scope.cancel = () => {
              $dialog.dismiss('cancel');
            };

            // ON CHANGE
            $scope.today = () => {
              $scope.from = moment.utc().startOf('d');
              $scope.to = moment.utc().endOf('d');
            };

            $scope.yesterday = () => {
              $scope.from = moment.utc().subtract(1, 'd').startOf('d');
              $scope.to = moment.utc().subtract(1, 'd').endOf('d');
            };

            $scope.last7days = () => {
              $scope.from = moment.utc().subtract(7, 'd').startOf('d');
              $scope.to = moment.utc().endOf('d');
            };

            $scope.onFromChange = () => {
              if ($scope.from.isAfter($scope.to)) {
                $scope.to = moment(angular.copy($scope.from)).endOf('d');
              }
            };

            $scope.onToChange = () => {
              if ($scope.to.isBefore($scope.from)) {
                $scope.from = moment(angular.copy($scope.to)).startOf('d');
              }
            }
          }]
        }).result;
      }
    }
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Dialogs',
      description: 'Dialog & Bottom Sheets',
      version: '1.0.3'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])

  .name;
