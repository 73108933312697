import angular from 'angular';
import pmdTabs from "./directives/tabs/pmdTabs";
import pmdPickers from "./directives/pickers/pmdPickers";
import pmdAlert from './directives/toasts/pmdAlert';
import pmdAccordion from "./directives/accordion/pmdAccordion";
import pmdProgress from "./directives/progress/pmdProgress";
import pmdDialog from "./directives/dialogs/pmdDialog";
import pmdTooltip from "./directives/others/pmdTooltip";
import pmdAvatar from "./directives/others/pmdAvatar";
import pmdCalendar from "./directives/others/pmdCalendar";
import pmdSelect from "./directives/inputs/pmdSelect";
import pmdScrollbar from "./directives/others/pmdScrollbar";
import pmdSlider from "./directives/others/pmdSlider";
import pmdPdfViewer from "./directives/pmdPdf/pmdPdfViewer";
import pmdCropper from "./directives/others/pmdCropper";
import pmdDropdown from "./directives/others/pmdDropdown";
import pmdTable from "./directives/table/pmdTable";
const MultiClamp = require('multi-clamp');

export default angular.module('app.interface.directives', [pmdTabs, pmdPickers, pmdAlert, pmdAccordion, pmdProgress, pmdDialog, pmdTooltip, pmdAvatar, pmdCalendar, pmdSelect, pmdScrollbar, pmdSlider, pmdPdfViewer, pmdCropper, pmdDropdown, pmdTable])
  .run(['$rootScope', ($rootScope) => {
  $rootScope.uiBase = {
    name: 'Børk',
    description: 'Streamline MD&B Base Template in AngularJS',
    version: '1.0.3'
  };
  }])
  // Small directives that don't need anything hardcore
  .directive('maxlines', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attrs, ngModel) {
        let maxLines = 1;
        attrs.$observe('maxlines', function (val) {
          maxLines = parseInt(val);
        });
        ngModel.$validators.maxlines = function (modelValue, viewValue) {
          let numLines = (modelValue || '').split("\n").length;
          return numLines <= maxLines;
        };
        attrs.$observe('maxlinesPreventEnter', function (preventEnter) {
          // if attribute value starts with 'f', treat as false. Everything else is true
          preventEnter = (preventEnter || '').toLocaleLowerCase().indexOf('f') !== 0;
          if (preventEnter) {
            addKeypress();
          } else {
            removeKeypress();
          }
        });

        function addKeypress() {
          elem.on('keypress', function (event) {
            // test if adding a newline would cause the validator to fail
            if (event.keyCode == 13 && !ngModel.$validators.maxlines(ngModel.$modelValue + '\n', ngModel.$viewValue + '\n')) {
              event.preventDefault();
            }
          });
        }

        function removeKeypress() {
          elem.off('.maxlines');
        }

        scope.$on('$destroy', removeKeypress);
      }
    };
  })
  .directive('errSrc', () => ({
    restrict: 'A',
    link: (scope, element, attrs) => {

      let loaded = false;

      const watcher = scope.$watch(() => attrs['ngSrc'], value => {
        if (!value) {
          element.attr('src', attrs.errSrc);
        }
      });

      element.bind('error', () => {
        if (!loaded) {
          element.attr('src', attrs.errSrc);
          loaded = true;
        } else
          element.attr('src', attrs.placeholder)
      });

      //unsubscribe on success
      element.bind('load', watcher);

    }
  }))
  .directive('errSrcFn', ['$parse', ($parse) => ({
    restrict: 'A',

    link: (scope, element, attrs) => {
      element.bind('error', () => {
        $parse(attrs.errSrcFn)(scope);
      });
    }
  })])
  .directive('clamp', () => {
    return ({
      restrict: 'A',
      link: (scope, el, att) => {
        new MultiClamp(el, {
            ellipsis: '...',
            clamp: att.clamp || 'auto'
          }
        );
      }
    });
  })
  .name;
