export default class AdsNewController {
  constructor(
    Publicitys,
    Domains,
    Files,
    Authorization,
    Auth,
    $state,
    $scope,
    FileUploader,
    UIService
  ) {
    this.publicidade = {
      section: null,
      id: 0,
    };
    this.aux = {
      section: null,
      range: null,
    };
    this.dominios = null;
    this.Publicitys = Publicitys;
    this.Domains = Domains;
    this.Files = Files;
    this.UI = UIService;
    this.Authorization = Authorization;
    this.FileUploader = FileUploader;
    this.$state = $state;
    this.$scope = $scope;
    this.publicidadeLoading = false;

    this.sections = [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
        opts: "Newsletter",
      },
    ];

    this.pubSizes = [
      {
        width: 250,
        height: 400,
      },
      {
        width: 680,
        height: 170,
      },
    ];

    // Uploader settings
    this.uploader = new this.FileUploader({
      url: "/api/assets/upload/publicities",
      queueLimit: 1,
    });
    // Force items to be only image
    let filter = {
      name: "verifyIMG",
      fn: function (item, options) {
        return item.type.indexOf("image/") !== -1;
      },
    };
    this.file = {
      id: 0,
      filetype: "jpg",
      userId: Auth.getId(),
      active: true,
    };

    this.hasImage = false;
    // Cropper API
    this.pmdCropper = {};
    $scope.$watch(
      () => {
        return this.pmdCropper.ready;
      },
      (a) => {
        this.hasImage = a;
      }
    );
    this.uploader.filters.push(filter);
    // Register news if image uploaded ok
    this.uploader.onSuccessItem = (item, response) => {
      this.fileUploadedOK = true;
      this.file.container = response.result.container;
      this.file.year = response.result.year;
      this.file.month = response.result.month;
      this.file.name = response.result.name;
      this.registerPub();
    };
    // Image upload failed
    this.uploader.onErrorItem = (response, status, headers) => {
      this.fileUploadedOK = false;
      this.UI.addToast("Erro ao carregar imagem");
      return;
    };
    this.loadData();
  }

  loadData = () => {
    this.Domains.find({
      filter: {
        where: {
          id: {
            inq: this.Authorization.getDomains(),
          },
        },
      },
    }).$promise.then((res) => {
      this.dominios = res;
      if (res.length === 1) {
        this.auxDominio.selected = res[0];
      }
    });
  };

  getImage = () => {
    let dataURItoBlob = (dataURI) => {
      let byteString = atob(dataURI.toString().split(",")[1]);
      //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      //or mimeString if you want
      return new Blob([ab], {
        type: "image/jpeg",
      });
    };
    console.log(this.file);
    let base64 = this.pmdCropper.export();
    let blob = dataURItoBlob(base64);
    let name = `${this.UUID()}.jpg`;
    let file = new File([blob], name);
    // Create "dummy" file and add it to the uploader
    let dummy = new this.FileUploader.FileItem(this.uploader, {
      lastModifiedData: new Date(),
      type: "image/jpeg",
      name: name,
    });
    dummy._file = file;
    this.uploader.queue = [];
    this.uploader.queue.push(dummy);
    this.uploader.uploadAll();
  };

  UUID = () => {
    let uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  };

  pickImage = () => {
    if (this.aux.section == null) {
      this.UI.addToast("Escolha uma secção primeiro!");
    } else {
      angular.element("#fileinput").trigger("click");
      this.hasImage = true;
    }
  };

  clearImage = () => {
    this.hasImage = false;
    this.pmdCropper.clear();
  };

  setSection = (section) => {
    this.hasImage = false;
    this.aux.section = section;
    if (section.id != 4) {
      this.aux.imageL = this.pubSizes[0];
    } else {
      this.aux.imageL = this.pubSizes[1];
    }
    this.hasImage = true;
  };

  preview = () => {
    let base64 = this.pmdCropper.export();

    if (base64 != undefined) {
      this.UI.showDialog({
        template: require("./preview.dialog.html"),
        controller: [
          "$scope",
          ($scope) => {
            $scope.data = base64;

            $scope.ok = () => {
              $scope.$close();
            };
          },
        ],
      });
    }
  };

  showDateRangePicker = () => {
    let from = undefined;
    let to = undefined;
    if (this.aux.range && this.aux.range.hasOwnProperty("value")) {
      from = this.aux.range.value.from;
      to = this.aux.range.value.to;
    }
    this.UI.showDateRangePicker(from, to, true).then((a) => {
      if (a) {
        this.aux.range = this.aux.range || {};
        this.aux.range.dr = `${a.from.format("DD/MM/YYYY")} - ${a.to.format(
          "DD/MM/YYYY"
        )}`;
        this.aux.range.value = a;
      }
    });
  };

  createPub = () => {
    this.Publicitys.create(this.publicidade)
      .$promise.then((pub) => {
        this.file.publicityId = pub.id;
        if (this.fileUploadedOK) {
          this.file.domainId = this.aux.dominio.selected.id;
          this.file.path = `api/assets/download/${this.file.container}/${this.file.year}/${this.file.month}/${this.file.name}`;
          this.file.pathCompressed = this.file.path;
          this.file.pathThumbnail = this.file.path;
          this.Files.create(this.file)
            .$promise.then((r) => {
              console.log(r);
              this.leave();
            })
            .catch((e) => {
              console.log(e);
              this.UI.addToast("Ocorreu um erro a adicionar imagem");
            });
        }
      })
      .catch((e) => {
        console.log(e);
        this.UI.addToast("Ocorreu um erro a adicionar publicidade");
      });
  };

  registerPub = () => {
    this.publicidade.domainId = this.aux.dominio.selected.id;
    this.publicidade.section = this.aux.section.id;
    this.publicidade.dateInit = this.aux.range.value.from;
    this.publicidade.dateFinal = this.aux.range.value.to;
    this.publicidade.active = true;
    this.createPub();
  };

  leave = () => {
    this.UI.addToast("Publicidade adicionada com sucesso");
    this.$state.go("app.publicidade.list");
  };

  submit = () => {
    console.log(this.form.addPublicidade);
    console.log(this.aux);
    console.log(this.aux);
    if (
      this.form.addPublicidade.$invalid ||
      this.aux.section == null ||
      this.aux.range == null
    ) {
      this.UI.addToast("Existem erros no preenchimento do formulário");
    } else {
      this.getImage();
    }
  };
}

AdsNewController.$inject = [
  "Publicitys",
  "Domains",
  "Files",
  "AuthorizationService",
  "AuthenticationService",
  "$state",
  "$scope",
  "FileUploader",
  "UIService",
];
