import {routes} from "./routes";
import EdicoesViewController from "./view/view.controller";
import EdicoesListController from "./list/list.controller";
import EdicoesEditController from "./edit/edit.controller";

export default angular.module('app.issues', [])
  .config(routes)
  .controller('EdicoesViewController', EdicoesViewController)
  .controller('EdicoesListController', EdicoesListController)
  .controller('EdicoesEditController', EdicoesEditController)
  .name;
