export default class DashboardController {
  constructor(UIService, AuthorizationService, Issues, Magazines, $state) {
    this.UI = UIService;
    this.Authorization = AuthorizationService;
    this.Issues = Issues;
    this.Magazines = Magazines;
    this.edicaoLoading = true;
    this.$state = $state;
    this.getEdicao();
    this.getMagazines();
    this.calendarEditions = [];
    this.calendarOptions = {
      eventRender: (event, eventElement) => {
        eventElement.find("div.fc-content").prepend("<img src='" + event.cover + "' width='58' height='83'>");
      },
      eventClick: (event) => {
        this.$state.go('app.edicoes.details', {
          id: event.id
        });
      },
      defaultView: 'month',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      allDaySlot: true,
      timeFormat: "HH:mm",
      navLinks: true,
      height: 'auto',
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 0,
      nowIndicator: false,
      weekNumberTitle: 'Sem.',
      eventLimitText: 'edições',
      noEventsMessage: 'Sem edições para apresentar.',
      weekNumbers: false,
      eventLimit: false,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    }
  };

  createCalendarEvents = (edicao) => {
    this.calendarEditions = [];
    edicao.forEach(e => {
      let cover = (e.urlCover === null) ? `/api/Upload/files/download/${e.magazines.dominio.logoPath}` : e.urlCover;
      let title = (e.urlCover === null) ? 'SEM CAPA' : `${e.magazines.dominio.name}`;
      this.calendarEditions.push({
        id: e.id,
        cover: cover,
        title: title,
        start: moment(e.dateIssued).startOf('d'),
        allDay: true,
        backgroundColor: this.fetchColor(e.magazineId),
        borderColor: "#FFffffff"
      });
    });
  };

  fetchColor = (id) => {
    let color;
    switch (id) {
      case 6:
      case 21:
      case 25:
      case 1:
        color = "#0065A7";
        break;
      case 2:
      case 7:
      case 22:
      case 26:
        color = "#0088A5";
        break;
      default:
        color = "#BA0304";
        break;
    }
    return color;
  };

  getEdicao = () => {
    this.edicaoLoading = true;
    this.Issues.find({
      filter: {
        where: {
          active: 1,
          domainId: {
            inq: this.Authorization.getDomains()
          }
        },
        limit: 100,
        order: 'id desc',
        include: {
          relation: 'magazines',
          scope: {
            include: 'dominio',
            where: {
              active: 1
            }
          }
        },
      }
    }).$promise.then((res) => {

      this.createCalendarEvents(res);


      this.edicaoLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getMagazines = () => {
    this.Magazines.find({
      filter: {
        where: {
          active: 1,
          id: {
            between: [1, 4]
          },
          domainId: {
            inq: this.Authorization.getDomains()
          }
        }
      }
    }).$promise.then((res) => {
      this.magazines = res;
    }).catch((error) => {
      console.log(error);
    })
  };
};

DashboardController.$inject = ['UIService', 'AuthorizationService', 'Issues', 'Magazines', '$state'];
