export default class EdicoesListController {
  constructor(AuthorizationService, Timelines, UIService, $state, NgTableParams, Issues, $filter, $q, $timeout) {
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.$timeout = $timeout;
    this.Issues = Issues;
    this.timeline = null;
    this.edicao = null;
    this.$q = $q;
    this.Timelines = Timelines;
    this.NgTableParams = NgTableParams;
    this.$filter = $filter;
    this.edicaoLoading = true;
    this.$state = $state;
    this.opt = $state.params;
    this.columns = [
      {
        id: 'dateIssued',
        name: 'Data de criação',
        type: 'd'
      },
      {
        id: 'domainId',
        name: 'Jornal',
        type: 's',
        values: [
          {
            id: 2,
            name: "Diário de Coimbra"
          },
          {
            id: 5,
            name: "Diário de Aveiro"
          },
          {
            id: 3,
            name: "Diário de Leiria"
          },
          {
            id: 4,
            name: "Diário de Viseu"
          },
        ]
      },
      {
        id: 'mancheteId',
        name: 'Manchete',
        type: 'n'
      },
    ];
    this.filters = [
      {
        val: 'a',
        name: 'Igual',
        type: 't',
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 't',
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 't',
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 't',
      },
      {
        val: 'e',
        name: 'Contém',
        type: 't',
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'n',
      },
      {
        val: 'b',
        name: 'Diferente (≠)',
        type: 'n',
      },
      {
        val: 'c',
        name: 'Maior (>)',
        type: 'n',
      },
      {
        val: 'd',
        name: 'Menor (<)',
        type: 'n',
      },
      {
        val: 'e',
        name: 'Maior ou igual (≥)',
        type: 'n',
      },
      {
        val: 'f',
        name: 'Menor ou igual (≤)',
        type: 'n',
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's',
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's',
      }
    ];
    this.customFilters = [];
    if (this.opt.filter) {
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              filterBy: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            data.oldColumn = angular.copy(data.column.selected);
            if (data.column.selected.type === 'd') {
              let d = a[2].split("-");
              if (d.length !== 2) {
                throw Error();
              }
              let from = moment(d[0], "DD/MM/YYYY").startOf('d');
              let to = moment(d[1], "DD/MM/YYYY").endOf('d');
              data.filterBy.selected = {type: 'd'};
              data.value = {from: from, to: to};
              data.dr = `${from.format('DD/MM/YYYY')} - ${to.format('DD/MM/YYYY')}`;
            } else {
              data.filterBy.selected = this.filters.find(f => {
                return f.val === a[1] && f.type === data.column.selected.type;
              });
              if (angular.isUndefined(data.filterBy.selected)) {
                throw Error();
              }
              data.oldFilter = angular.copy(data.filterBy.selected);
              if (data.filterBy.selected.type === 's') {
                data.value.selected = data.column.selected.values.find(f => {
                  // Double compare only to ignore strict type check
                  return f.id == a[2];
                });
                if (angular.isUndefined(data.value.selected)) {
                  throw Error();
                }
              } else {
                data.value = a[2];
              }
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
            this.applyFilter();
          }
        }
      });
    }
    this.getEdicao();
  };

  showIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty('column'))
        return filter.column.selected.type === 'd';
      return false;

    }
    return false;
  };

  hideIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty('column'))
        return filter.column.selected.type !== 'd';
      return false;

    }
    return false;
  };

  oldColumn = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty('oldColumn')) {
        if (row.oldColumn.type !== item.type) {
          row.filterBy = undefined;
          row.value = undefined;
        }
      }
      if (row)
        row.oldColumn = angular.copy(item);
      if (item.type === 'd') {
        row.filterBy = {selected: {type: 'd', value: 'a'}};
      }
    })
  };

  showDateRangePicker = filter => {
    let from = undefined;
    let to = undefined;
    if (filter.hasOwnProperty(filter.value)) {
      from = filter.value.from;
      to = filter.value.to;
    }
    this.UI.showDateRangePicker(from, to).then(a => {
      if (a) {
        filter.dr = `${a.from.format('DD/MM/YYYY')} - ${a.to.format('DD/MM/YYYY')}`;
        filter.value = a;
      }
    });
  };

  oldFilter = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty('oldFilter')) {
        if (row.oldFilter.type !== item.type) {
          row.value = undefined;
        } else {
          if (item.type === 'd') {
            switch (item.val) {
              case 'a':
                row.value = moment.utc();
                // Today:
                break;
              case 'b':
                row.value = moment.utc();
                // Ontem
                break;
              case 'c':
                // Últimos 7 dias
                break;
              case 'd':
                // Personalizado
                break;
            }
          }
        }
      }
      if (row)
        row.oldFilter = angular.copy(item);
    });
  };

  applyFilter = () => {
    if (this.customFilters.length === 0) {
      // Remove filters
      this.fs = false;
      this.opt.page = 1;
      this.opt.filter = undefined;
      this.$state.go('app.edicoes.list', this.opt, {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: 'replace',
        // inherit the current params on the url
        inherit: true
      });
      this.getEdicao();
    } else {
      let string = "";
      let keepGoing = true;
      this.customFilters.forEach(filter => {
        if (filter.hasOwnProperty('column') && filter.hasOwnProperty('filterBy') && filter.hasOwnProperty('value')) {
          if (filter.column.hasOwnProperty('selected') && filter.filterBy.hasOwnProperty('selected')) {
            if (filter.filterBy.selected.type === 's' && !filter.value.hasOwnProperty('selected')) {
              keepGoing = false;
              return;
            }
            string += `${filter.column.selected.id}`;
            if (filter.column.selected.type === 'd') {
              string += `·a·${filter.value.from.format('DD/MM/YYYY')}-${filter.value.to.format('DD/MM/YYYY')}`;
            } else {
              string += `·${filter.filterBy.selected.val}.${filter.value.hasOwnProperty('selected') ? filter.value.selected.id : filter.value}${this.customFilters.indexOf(filter) === this.customFilters.length - 1 ? '' : ':'}`;
            }
          } else {
            keepGoing = false;
            return;
          }
        } else {
          keepGoing = false;
          return;
        }
      });
      if (!keepGoing) {
        return;
      }
      if (!angular.equals(string, "")) {
        this.opt.page = 1;
        this.opt.filter = string;
        this.$state.go('app.edicoes.list', this.opt, {
          // prevent the events onStart and onSuccess from firing
          notify: false,
          // prevent reload of the current state
          reload: false,
          // replace the last record when changing the params so you don't hit the back button and get old params
          location: 'replace',
          // inherit the current params on the url
          inherit: true
        });
      }
      this.fs = false;
      this.getEdicao();
    }
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  removerEdicao = (a) => {
    this.UI.showConfirm(`Deseja remover esta edição do ${a.magazines.dominio.name}?`).then(res => {
      if (res) {
        a.active = 0;
        a.uuid = this.generateUUID();
        a.title = a.dateIssued;
        this.Issues.upsert(a).$promise.then(() => {
          this.getEdicao();
          this.UI.addToast("Edição removida com sucesso!");
        });
      }
    });
  };

  getEdicao = () => {
    this.edicaoLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    let whereFilter = {
      active: 1,
      domainId: {
        inq: this.Authorization.getDomains()
      }
    };
    if (angular.isDefined(this.opt.filter)) {
      this.customFilters.forEach(filter => {
        let col = filter.column.selected.id;
        let query;
        // Handle type TEXT
        if (filter.column.selected.type === 't') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              // Texto igual
              query = filter.value;
              break;
            case 'b':
              // Texto diferente
              query = {
                neq: filter.value
              };
              break;
            case 'c':
              // Texto começa por
              query = {
                like: `${filter.value}%`
              };
              break;
            case 'd':
              // Texto termina com
              query = {
                like: `%${filter.value}`
              };
              break;
            case 'e':
              // Texto contém
              query = {
                like: `%${filter.value}%`
              };
              break;
            default:
              break;
          }
        }
        // Handle type NUMBER
        else if (filter.column.selected.type === 'n') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              // Número igual
              query = filter.value;
              break;
            case 'b':
              // Número não igual
              query = {
                neq: filter.value
              };
              break;
            case 'c':
              // Número maior que
              query = {
                gt: filter.value
              };
              break;
            case 'd':
              // Número menor que
              query = {
                lt: filter.value
              };
              break;
            case 'e':
              // Número maior ou igual a
              query = {
                gte: filter.value
              };
              break;
            case 'f':
              // Número menor ou igual a
              query = {
                lte: filter.value
              };
              break;
            default:
              break;
          }
        }
        // Handle type SELECT
        else if (filter.column.selected.type === 's') {
          switch (filter.filterBy.selected.val) {
            case 'a':
              query = filter.value.selected.id;
              break;
            case 'b':
              query = {
                neq: filter.value.selected.id
              };
              break;
            default:
              break;
          }
        }
        // Handle date
        if (filter.column.selected.type === 'd') {
          if (filter.value.from.isSame(filter.value.to, 'd')) {
            query = filter.value.from.format('YYYY-MM-DD');
          }
          query = {between: [filter.value.from, filter.value.to]};
        }
        // Same column filter

        let a = {};
        a[col] = query;
        if (!whereFilter.hasOwnProperty('and')) {
          whereFilter.and = [];
        }
        whereFilter.and.push(a);
      });
    }
    if (!whereFilter.hasOwnProperty('domainId')) {
      whereFilter.domainId = {
        inq: this.Authorization.getDomains()
      }
    }
    this.Issues.count({
      fields: {id: true},
      where: whereFilter
    }).$promise.then((res) => {
      this.total = res.count;
      this.Issues.find({
        filter: {
          fields: {
            uuid: false,
            appleCode: false,
            size: false,
            title: false,
            price: false
          },
          where: whereFilter,
          order: order,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
          include: [{
            relation: 'magazines',
            scope: {
              where: {active: 1},
              include: {
                relation: 'dominio',
                scope: {
                  fields: {name: true},
                  where: {active: 1}
                }
              }
            }
          },
            {
              relation: 'manchete',
              scope: {
                fields: {title: true},
                where: {active: 1}
              }
            }]
        }
      }).$promise.then(issues => {
        let page = this.opt.page;
        let items = this.opt.items;
        let total = this.total;
        this.start = (page - 1) * items + 1;
        // Text
        if (((page - 1) * items + items) >= total)
          this.end = total;
        else
          this.end = Number.parseInt(this.start - 1 + items);

        this.edicao = issues;
        this.edicao.forEach(p => {
          if (this.opt.sort === 'asc') {
            p.index = this.start + this.edicao.indexOf(p);
          } else {
            p.index = this.total - this.start - this.edicao.indexOf(p) + 1;
          }
        });
        this.edicaoLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.edicoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getEdicao();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.edicoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getEdicao();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.edicoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getEdicao();
  };

  canFilter = (column) => {
    if (column.type === 't' || column.type === 'n') {
      return true;
    } else {
      return this.customFilters.find((f) => {
        if (f.hasOwnProperty('column')) {
          return f.column.selected.type === column.type;
        } else {
          return false;
        }

      }) === undefined;
    }
    return true;
  };


};

EdicoesListController.$inject = ['AuthorizationService', 'Timelines', 'UIService', '$state', 'NgTableParams', 'Issues', '$filter', '$q', '$timeout'];
