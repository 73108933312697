export default class ModalidadesController {
  constructor(Modalidades, Magazines, $http, NgTableParams, $rootScope, $state, $filter, FileUploader, UIService) {
    this.modalidades = null;
    this.magazines = null;
    this.Modalidades = Modalidades;
    this.Magazines = Magazines;
    this.modalidadesLoading = true;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.getModalidades();
    this.getMagazines();
  };

  getMagazines = () => {
    this.Magazines.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.magazines = res;
    }).catch((error) => {
      console.log(error);
    })
  };

  getModalidades = () => {
    this.modalidadesLoading = true;
    this.Modalidades.find({
      filter: {
        where: {
          active: 1
        },
        include: {
          relation: 'magazines',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.modalidades = res;

      this.table = new this.NgTableParams({
        count: 20,
        sorting: {
          magazineId: 'asc'
        }
      }, {
        dataset: this.modalidades
      });

      this.modalidadesLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  removerModalidade = (a) => {
    this.Modalidades.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./removerModalidade.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Modalidade removida com sucesso!");
          this.getModalidades();
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  createModalidade = () => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarModalidade.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.modalidades = {};
        $scope.magazinesOptions = self.magazines;
        $scope.magazinesOption = {};
        $scope.label = "Adicionar Modalidade";
        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.Modalidades.create({
        id: 0,
        name: ok.modalidades.name,
        price: ok.modalidades.price,
        days: ok.modalidades.days,
        magazineId: ok.magazinesOption.id,
        active: 1
      }).$promise.then((res) => {
        this.getModalidades();
        this.UI.addToast("Modalidade adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro na criação da modalidade!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editarModalidade = (m) => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarModalidade.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.modalidades = {};
        $scope.modalidades.id = m.id;
        $scope.modalidades.name = m.name;
        $scope.modalidades.price = m.price;
        $scope.modalidades.days = m.days;
        $scope.magazinesOptions = self.magazines;
        $scope.magazinesOption = m.magazines;

        $scope.label = "Editar Modalidade";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);
    dialogEdit.then((a) => {
      this.Modalidades.findOne({
        filter: {
          where: {
            id: a.modalidades.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.modalidades.name;
        res.id = a.modalidades.id;
        res.price = a.modalidades.price;
        res.days = a.modalidades.days;
        res.magazineId = a.magazinesOption.id;
        res.$save().then((r) => {
          this.getModalidades();
          this.UI.addToast("Modalidade editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da modalidade!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };
};

ModalidadesController.$inject = ['Modalidades', 'Magazines', '$http', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', 'UIService'];
