import angular from 'angular';
import AuthorizationService from './authorization.service';

export default angular.module('app.authorization', [])
  .service('AuthorizationService', AuthorizationService)
  .directive('authorization', ['AuthorizationService', AuthorizationService => ({
    restrict: 'A',
    link: (scope, elem, attrs) => {
      let permission = attrs.permission;
      let group = attrs.group;
      if (!_.isEmpty(permission)) {
        if (!AuthorizationService.canPerform(permission)) {
          elem.remove();
        }
      }
      if (!_.isEmpty(group)) {
        if (!AuthorizationService.belongsTo(group)) {
          elem.remove();
        }

      }
    }
  })])
  .name;
