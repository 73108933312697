export default class AdsListController {
  constructor(
    Publicitys,
    $state,
    UIService,
    AuthorizationService,
    $timeout,
    $q
  ) {
    this.Authorization = AuthorizationService;
    this.$timeout = $timeout;
    this.Publicitys = Publicitys;
    this.newsLoading = true;
    this.UI = UIService;
    this.$q = $q;
    this.$state = $state;
    this.news = null;
    this.opt = $state.params;
    this.columns = [
      {
        id: "title",
        name: "Título",
        type: "t",
      },
      {
        id: "domainId",
        name: "Jornal",
        type: "s",
        values: [
          {
            id: 2,
            name: "Diário de Coimbra",
          },
          {
            id: 5,
            name: "Diário de Aveiro",
          },
          {
            id: 3,
            name: "Diário de Leiria",
          },
          {
            id: 4,
            name: "Diário de Viseu",
          },
        ],
      },
      {
        id: "section",
        name: "Secção",
        type: "s",
        values: [
          {
            id: 1,
            name: "Secção 1",
          },
          {
            id: 2,
            name: "Secção 2",
          },
          {
            id: 3,
            name: "Secção 3",
          },
          {
            id: 4,
            name: "Secção 4 (Newsletter)",
          },
        ],
      },
      {
        id: "dateInit",
        name: "Data de início",
        type: "d",
      },
    ];
    this.filters = [
      {
        val: "a",
        name: "Igual",
        type: "t",
      },
      {
        val: "b",
        name: "Diferente",
        type: "t",
      },
      {
        val: "c",
        name: "Começa por",
        type: "t",
      },
      {
        val: "d",
        name: "Termina com",
        type: "t",
      },
      {
        val: "e",
        name: "Contém",
        type: "t",
      },
      {
        val: "a",
        name: "Igual (=)",
        type: "n",
      },
      {
        val: "b",
        name: "Diferente (≠)",
        type: "n",
      },
      {
        val: "c",
        name: "Maior (>)",
        type: "n",
      },
      {
        val: "d",
        name: "Menor (<)",
        type: "n",
      },
      {
        val: "e",
        name: "Maior ou igual (≥)",
        type: "n",
      },
      {
        val: "f",
        name: "Menor ou igual (≤)",
        type: "n",
      },
      {
        val: "a",
        name: "Igual",
        type: "s",
      },
      {
        val: "b",
        name: "Diferente",
        type: "s",
      },
    ];
    this.customFilters = [];
    if (this.opt.filter) {
      let filters = this.opt.filter.split(":");
      filters.forEach((filter) => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              filterBy: {},
              value: {},
            };
            data.column.selected = this.columns.find((f) => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            data.oldColumn = angular.copy(data.column.selected);
            if (data.column.selected.type === "d") {
              let d = a[2].split("-");
              if (d.length !== 2) {
                throw Error();
              }
              let from = moment(d[0], "DD/MM/YYYY").startOf("d");
              let to = moment(d[1], "DD/MM/YYYY").endOf("d");
              data.filterBy.selected = { type: "d" };
              data.value = { from: from, to: to };
              data.dr = `${from.format("DD/MM/YYYY")} - ${to.format(
                "DD/MM/YYYY"
              )}`;
            } else {
              data.filterBy.selected = this.filters.find((f) => {
                return f.val === a[1] && f.type === data.column.selected.type;
              });
              if (angular.isUndefined(data.filterBy.selected)) {
                throw Error();
              }
              data.oldFilter = angular.copy(data.filterBy.selected);
              if (data.filterBy.selected.type === "s") {
                data.value.selected = data.column.selected.values.find((f) => {
                  // Double compare only to ignore strict type check
                  return f.id == a[2];
                });
                if (angular.isUndefined(data.value.selected)) {
                  throw Error();
                }
              } else {
                data.value = a[2];
              }
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast("Não foi possível carregar filtros");
            this.applyFilter();
          }
        }
      });
    }
    this.getAds();
  }

  showIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty("column"))
        return filter.column.selected.type === "d";
      return false;
    }
    return false;
  };

  hideIfDate = (filter) => {
    if (angular.isDefined(filter)) {
      if (filter.hasOwnProperty("column"))
        return filter.column.selected.type !== "d";
      return false;
    }
    return false;
  };

  oldColumn = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty("oldColumn")) {
        if (row.oldColumn.type !== item.type) {
          row.filterBy = undefined;
          row.value = undefined;
        }
      }
      if (row) row.oldColumn = angular.copy(item);
      if (item.type === "d") {
        row.filterBy = { selected: { type: "d", value: "a" } };
      }
    });
  };

  showDateRangePicker = (filter) => {
    let from = undefined;
    let to = undefined;
    if (filter.hasOwnProperty(filter.value)) {
      from = filter.value.from;
      to = filter.value.to;
    }
    this.UI.showDateRangePicker(from, to).then((a) => {
      if (a) {
        filter.dr = `${a.from.format("DD/MM/YYYY")} - ${a.to.format(
          "DD/MM/YYYY"
        )}`;
        filter.value = a;
      }
    });
  };

  oldFilter = (item, row) => {
    this.$timeout(() => {
      if (row && row.hasOwnProperty("oldFilter")) {
        if (row.oldFilter.type !== item.type) {
          row.value = undefined;
        } else {
          if (item.type === "d") {
            switch (item.val) {
              case "a":
                row.value = moment.utc();
                // Today:
                break;
              case "b":
                row.value = moment.utc();
                // Ontem
                break;
              case "c":
                // Últimos 7 dias
                break;
              case "d":
                // Personalizado
                break;
            }
          }
        }
      }
      if (row) row.oldFilter = angular.copy(item);
    });
  };

  applyFilter = () => {
    if (this.customFilters.length === 0) {
      // Remove filters
      this.fs = false;
      this.opt.page = 1;
      this.opt.filter = undefined;
      this.$state.go("app.ads.list", this.opt, {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: "replace",
        // inherit the current params on the url
        inherit: true,
      });
      this.getAds();
    } else {
      let string = "";
      let keepGoing = true;
      this.customFilters.forEach((filter) => {
        if (
          filter.hasOwnProperty("column") &&
          filter.hasOwnProperty("filterBy") &&
          filter.hasOwnProperty("value")
        ) {
          if (
            filter.column.hasOwnProperty("selected") &&
            filter.filterBy.hasOwnProperty("selected")
          ) {
            if (
              filter.filterBy.selected.type === "s" &&
              !filter.value.hasOwnProperty("selected")
            ) {
              keepGoing = false;
              return;
            }
            string += `${filter.column.selected.id}`;
            if (filter.column.selected.type === "d") {
              string += `·a·${filter.value.from.format(
                "DD/MM/YYYY"
              )}-${filter.value.to.format("DD/MM/YYYY")}`;
            } else {
              string += `·${filter.filterBy.selected.val}.${
                filter.value.hasOwnProperty("selected")
                  ? filter.value.selected.id
                  : filter.value
              }${
                this.customFilters.indexOf(filter) ===
                this.customFilters.length - 1
                  ? ""
                  : ":"
              }`;
            }
          } else {
            keepGoing = false;
            return;
          }
        } else {
          keepGoing = false;
          return;
        }
      });
      if (!keepGoing) {
        return;
      }
      if (!angular.equals(string, "")) {
        this.opt.page = 1;
        this.opt.filter = string;
        this.$state.go("app.ads.list", this.opt, {
          // prevent the events onStart and onSuccess from firing
          notify: false,
          // prevent reload of the current state
          reload: false,
          // replace the last record when changing the params so you don't hit the back button and get old params
          location: "replace",
          // inherit the current params on the url
          inherit: true,
        });
      }
      this.fs = false;
      this.getAds();
    }
  };

  hasSelect = () => {
    return _.some(this.noticias, function (r) {
      return r.selected === true;
    });
  };

  selectItem = () => {
    this.selected = _.filter(this.noticias, function (r) {
      return r.selected === true;
    }).length;
  };

  removerNoticia = (a) => {
    this.newsLoading = false;
    let defer = this.$q.defer();
    this.UI.showConfirm(`Deseja remover esta publicidade?`).then((res) => {
      if (res) {
        a.active = 0;
        this.Publicitys.upsert(a).$promise.then(() => {
          this.getAds();
          this.UI.addToast("Publicidade removida com sucesso!");
        });
      }
    });
  };

  getAds = () => {
    this.newsLoading = true;
    let order = `${this.opt.order} ${this.opt.sort}`;
    // Start setting filter
    /* Rules:
      1 - If filter has type of and or inq, they should be defined after custom filters - look up // End setting filter
     */
    let whereFilter = {
      active: 1,
    };
    if (angular.isDefined(this.opt.filter)) {
      this.customFilters.forEach((filter) => {
        let col = filter.column.selected.id;
        let query;
        // Handle type TEXT
        if (filter.column.selected.type === "t") {
          switch (filter.filterBy.selected.val) {
            case "a":
              // Texto igual
              query = filter.value;
              break;
            case "b":
              // Texto diferente
              query = {
                neq: filter.value,
              };
              break;
            case "c":
              // Texto começa por
              query = {
                like: `${filter.value}%`,
              };
              break;
            case "d":
              // Texto termina com
              query = {
                like: `%${filter.value}`,
              };
              break;
            case "e":
              // Texto contém
              query = {
                like: `%${filter.value}%`,
              };
              break;
            default:
              break;
          }
        }
        // Handle type NUMBER
        else if (filter.column.selected.type === "n") {
          switch (filter.filterBy.selected.val) {
            case "a":
              // Número igual
              query = filter.value;
              break;
            case "b":
              // Número não igual
              query = {
                neq: filter.value,
              };
              break;
            case "c":
              // Número maior que
              query = {
                gt: filter.value,
              };
              break;
            case "d":
              // Número menor que
              query = {
                lt: filter.value,
              };
              break;
            case "e":
              // Número maior ou igual a
              query = {
                gte: filter.value,
              };
              break;
            case "f":
              // Número menor ou igual a
              query = {
                lte: filter.value,
              };
              break;
            default:
              break;
          }
        }
        // Handle type SELECT
        else if (filter.column.selected.type === "s") {
          switch (filter.filterBy.selected.val) {
            case "a":
              query = filter.value.selected.id;
              break;
            case "b":
              query = {
                neq: filter.value.selected.id,
              };
              break;
            default:
              break;
          }
        }
        // Handle date
        if (filter.column.selected.type === "d") {
          if (filter.value.from.isSame(filter.value.to, "d")) {
            query = filter.value.from.format("YYYY-MM-DD");
          }
          query = { between: [filter.value.from, filter.value.to] };
        }
        // Same column filter

        let a = {};
        a[col] = query;
        if (!whereFilter.hasOwnProperty("and")) {
          whereFilter.and = [];
        }
        whereFilter.and.push(a);
      });
    }
    // End setting filter
    /* Rules:
      1- Check first if your property already has a custom filter for inq/and, if so, ignore your filter
      2 - Add your filters here that has the inq/add properties
     */
    if (!whereFilter.hasOwnProperty("domainId")) {
      whereFilter.domainId = {
        inq: this.Authorization.getDomains(),
      };
    }

    this.Publicitys.count({
      fields: { id: true },
      where: whereFilter,
    })
      .$promise.then((res) => {
        this.total = res.count;
        this.Publicitys.find({
          filter: {
            where: whereFilter,
            order: order,
            limit: this.opt.items,
            skip: (this.opt.page - 1) * this.opt.items,
            include: [
              {
                relation: "files",
                scope: {
                  where: { active: 1 },
                },
              },
              {
                relation: "dominio",
                scope: {
                  where: { active: 1 },
                },
              },
            ],
          },
        })
          .$promise.then((news) => {
            let page = this.opt.page;
            let items = this.opt.items;
            let total = this.total;

            this.start = total > 0 ? (page - 1) * items + 1 : 0;
            if (this.start - 1 + items + items >= total) {
              this.end = total;
            } else {
              this.end = Number.parseInt(this.start - 1 + items);
            }

            this.noticias = news;

            this.noticias.forEach((p) => {
              if (this.opt.sort === "asc") {
                p.index = this.start + this.noticias.indexOf(p);
              } else {
                p.index =
                  this.total - this.start - this.noticias.indexOf(p) + 1;
              }
            });
            this.newsLoading = false;
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  canFilter = (column) => {
    if (column.type === "t" || column.type === "n") {
      return true;
    } else {
      return (
        this.customFilters.find((f) => {
          if (f.hasOwnProperty("column")) {
            return f.column.selected.type === column.type;
          } else {
            return false;
          }
        }) === undefined
      );
    }
    return true;
  };

  sort = (keyname) => {
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.ads.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getAds();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.ads.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getAds();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.ads.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getAds();
  };
}

AdsListController.$inject = [
  "Publicitys",
  "$state",
  "UIService",
  "AuthorizationService",
  "$timeout",
  "$q",
];
