export default class NoticiasViewController {
  constructor(News, Files, Timelines, Domains, NgTableParams, $rootScope, $state, $stateParams, $filter, UIService, $http, FileUploader) {
    this.new = null;
    this.noticiasPub = null;
    this.file = null;
    this.dominios = null;
    this.News = News;
    this.Timelines = Timelines;
    this.Domains = Domains;
    this.Files = Files;
    this.id = $stateParams.id;
    this.$http = $http;
    this.NgTableParams = NgTableParams;
    this.FileUploader = FileUploader;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.newLoading = true;
    this.getDetalhesNoticia(this.id);
  };

  getDetalhesNoticia = (id) => {
    this.newLoading = true;
    this.News.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: [{
          relation: 'user',
          scope: {
            fields: {
              name: true,
              id: true
            }
          }
        },
          {
            relation: 'files',
            scope: {
              where: {active: 1}
            }
          },
          {
            relation: 'dominio',
            scope: {
              where: {active: 1}
            }
          },
          {
            relation: 'timeline',
            scope: {
              where: {active: 1}
            }
          }
        ]
      }
    }).$promise.then((res) => {
      this.new = res;
      this.newLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };
};

NoticiasViewController.$inject = ['News', 'Files', 'Timelines', 'Domains', 'NgTableParams', '$rootScope', '$state', '$stateParams', '$filter', 'UIService', '$http', 'FileUploader'];
