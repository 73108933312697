export function routes($stateProvider) {
  $stateProvider
    .state('app.edicoes', {
      template: '<ui-view></ui-view>',
      url: '/edicao',
      abstract: true
    })
    .state('app.edicoes.list', {
      template: require('./list/list.view.html'),
      url: '?{page:int}&{items:int}&order&sort',
      title: 'Edições',
      role: 'issuesList',
      controller: 'EdicoesListController',
      controllerAs: 'vm',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "dateIssued",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        },
      }
    })
    .state('app.edicoes.details', {
      template: require('./view/view.view.html'),
      url: '/{id}',
      role: 'issuesList',
      title: 'Detalhes da Edição',
      controller: 'EdicoesViewController',
      controllerAs: 'vm',
    })
    .state('app.edicoes.edit', {
      template: require('./edit/edit.view.html'),
      url: '/{id}/edit',
      controller: 'EdicoesEditController',
      controllerAs: 'vm',
    });
}


routes.$inject = ['$stateProvider'];
