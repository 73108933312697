export default class DetalhesModalidadesController {
  constructor(Modalidades, Magazines, NgTableParams, $rootScope, $state, $stateParams, $filter, UIService, $http) {
    this.modalidade = null;
    this.magazines = null;
    this.id = $stateParams.id;
    this.$http = $http;
    this.Modalidades = Modalidades;
    this.Magazines = Magazines;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.modalidadeLoading = true;
    this.getDetalhesModalidade(this.id);
    this.getMagazines();
  };

  getDetalhesModalidade = (id) => {
    this.modalidadeLoading = true;
    this.Modalidades.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include:
          {
            relation: 'magazines',
            scope: {
              where: {
                active: 1
              },
              include: {
                relation: 'dominio',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }
            }
          }
      }
    }).$promise.then((res) => {
      this.modalidade = res;
      this.modalidadeLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  getMagazines = () => {
    this.Magazines.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.magazines = res;
    }).catch((error) => {
      console.log(error);
    })
  };

  editarModalidade = (m) => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./editarModalidadeDetalhes.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.modalidade = {};
        $scope.modalidade.id = m.id;
        $scope.modalidade.name = m.name;
        $scope.modalidade.price = m.price;
        $scope.modalidade.days = m.days;
        $scope.magazinesOptions = self.magazines;
        $scope.magazinesOption = m.magazines;

        $scope.label = "Editar Modalidade";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);
    dialogEdit.then((a) => {
      this.Modalidades.findOne({
        filter: {
          where: {
            id: a.modalidade.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.modalidade.name;
        res.id = a.modalidade.id;
        res.price = a.modalidade.price;
        res.days = a.modalidade.days;
        res.magazineId = a.magazinesOption.id;
        res.$save().then((r) => {
          this.getDetalhesModalidade(this.id);
          this.UI.addToast("Modalidade editada com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição da modalidade!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };
};

DetalhesModalidadesController.$inject = ['Modalidades', 'Magazines', 'NgTableParams', '$rootScope', '$state', '$stateParams', '$filter', 'UIService', '$http'];
