export default class PublicidadeController {
  constructor(Publicitys, Domains, $http, NgTableParams, $rootScope, $state, $filter, FileUploader, UIService) {
    this.publicidade = null;
    this.dominios = null;
    this.Publicitys = Publicitys;
    this.Domains = Domains;
    this.publicidadeLoading = true;
    this.UI = UIService;
    this.$http = $http;
    this.NgTableParams = NgTableParams;
    this.FileUploader = FileUploader;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.getPublicidade();
    this.getDominios();
  };

  getPublicidade = () => {
    this.publicidadeLoading = true;
    this.Publicitys.find({
      filter: {
        where: {
          active: 1
        },
        order: 'dateInit desc, dateFinal desc',
        include: {
          relation: 'dominio',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {

      this.publicidade = res;

      this.table = new this.NgTableParams({
        count: 20,
        sorting: {
          name: 'asc'
        }
      }, {
        dataset: this.publicidade
      });

      this.publicidadeLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  // Returns an array of publicidade where domainId = id.
  getPublicidadeForDomain = (id) =>_.filter(this.publicidade, (p) => p.domainId === id);

  getDominios = () => {
    this.Domains.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.dominios = res;
    }).catch((error) => {
      console.log(error);
    })
  };

  removerPublicidade = (a) => {
    this.Publicitys.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./removerPublicidade.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Publicidade removida com sucesso!");
          this.getPublicidade();
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };
};

PublicidadeController.$inject = ['Publicitys', 'Domains', '$http', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', 'UIService'];
