export default class ClientesDetalhesController {
  constructor(Clients, ClientsDevices, Magazines, GroupNewsstand, ClientsMagazines, NgTableParams, $rootScope, $state, $stateParams, $filter, UIService, AuthenticationService) {
    this.client = null;
    this.ClientsDevices = ClientsDevices;
    this.magazines = null;
    this.group = null;
    this.subscriptions = [];
    this.modalidades = null;
    this.Clients = Clients;
    this.ClientsMagazines = ClientsMagazines;
    this.GroupNewsstand = GroupNewsstand;
    this.Magazines = Magazines;
    this.id = $stateParams.id;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.palavraEsquecimento = "ESQUECER";
    this.clientsLoading = true;
    this.Auth = AuthenticationService;
    this.getDetalhesCliente(this.id);
    this.getGroups();
    this.getMagazines();
    this.checkbox = [];
  };

  getMagazines = () => {
    this.Magazines.find({
      filter: {
        where: {
          active: 1
        },
        include: {
          relation: 'Climagazines',
          scope: {
            where: {
              clientId: this.id,
              active: 1
            }
          }
        }
      }
    }).$promise.then((res) => {
      this.magazines = res;
    }).catch((error) => {
      console.log(error);
    })
  };

  getGroups = () => {
    this.GroupNewsstand.find({
      filter: {
        where: {
          active: 1
        },
        include: {
          relation: 'magazines',
          scope: {
            where: {
              active: 1
            },
            include: [{
                relation: 'modalidades',
                scope: {
                  where: {
                    active: 1
                  }
                }
              },
              {
                relation: 'Climagazines',
                scope: {
                  where: {
                    clientId: this.id,
                    active: 1
                  }
                }
              }
            ]
          }
        }
      }
    }).$promise.then((res) => {
      this.group = res;
      this.group.forEach(g => {
        g.magazines.forEach(p => {
          if (p.Climagazines.length > 0) {
            p.startSubscription = p.Climagazines[0].start;
            p.endSubscription = p.Climagazines[0].end;
            p.checkbox = p.Climagazines[0].active === 1;
          } else {
            p.startSubscription = null;
            p.endSubscription = null;
            p.checkbox = 0;
          }
        });
      });
    }).catch((error) => {
      console.log(error);
    })
  };

  editarCliente = (m) => {
    let options = {
      size: 'lg',
      template: require('./editClienteDetails.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.client = {};
        $scope.client.id = m.id;
        $scope.client.name = m.name;
        $scope.client.email = m.email;
        $scope.client.nif = m.nif;
        $scope.client.telephone = m.telephone;
        $scope.client.dateRegistration = m.dateRegistration;
        $scope.client.morada = m.morada;
        $scope.client.localidade = m.localidade;
        $scope.client.codigoPostal = m.codigoPostal;
        $scope.client.maxDevices = m.maxDevices;

        $scope.label = "Editar cliente";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Clients.findOne({
        filter: {
          where: {
            id: a.client.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.id = a.client.id;
        res.name = a.client.name;
        res.email = a.client.email;
        res.nif = a.client.nif;
        res.telephone = a.client.telephone;
        res.dateRegistration = a.client.dateRegistration;
        res.morada = a.client.morada;
        res.localidade = a.client.localidade;
        res.codigoPostal = a.client.codigoPostal;
        res.maxDevices = a.client.maxDevices;
        res.$save().then((r) => {
          this.getDetalhesCliente(this.id);
          this.UI.addToast("Cliente editado com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro na edição do cliente!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  addTimeToSignature = (signature, monthsToAdd) => {
    let user = this.Auth.getUser();
    let newStart = moment();
    let newEnd = moment().add(monthsToAdd, 'months');
    signature.start = newStart;
    signature.end = newEnd;
    signature.comments = `Adicionados ${monthsToAdd} meses à assinatura por ${user.name}.`;
    this.ClientsMagazines.upsert(signature).$promise.then(r => {
            this.getDetalhesCliente(this.id);
    }).catch(err => {
      this.getDetalhesCliente(this.id);
      this.UI.addToast("Não foi possível renovar assinatura. Contacte o suporte.");
    });
  }

  getDetalhesCliente = (id) => {
    this.clientsLoading = true;
    this.Clients.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: [{
          relation: 'CliMagazineRel',
          scope: {
            where: {
              active: 1
            }
          }
        }, 'Devices']
      }
    }).$promise.then((res) => {
      this.client = res;
      this.clientsLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  removeDevice = (device) => {
    this.UI.showConfirm('Deseja remover o equipamento?').then(r => {
      if (r) {
        this.ClientsDevices.destroyById({
          id: device.id
        }).$promise.then(d => {
          this.UI.addToast("Equipamento removido");
          this.getDetalhesCliente(this.id);
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao remover equipamento");
          this.getDetalhesCliente(this.id);
        })
      }
    });
  }

  // Triggered when clicking on subscription
  // Update value in database for client
  toggleClientMagazine = (p, s) => {
    // If subscribing
    if (s) {
      this.ClientsMagazines.findOne({
        filter: {
          where: {
            magazineId: p.id,
            clientId: this.id
          }
        }
      }).$promise.then((res) => {
        if (res != null) {
          // Exists one record for this client - we need to update fields and save
          res.active = 1;
          // Save and update stuff
          res.$save().then(() => {
            p.active = res.active === 1;
            location.reload(true);
            this.UI.addToast("A subscrição foi alterada com sucesso!");
            this.getDetalhesCliente(this.id);

          }).catch((error) => {
            this.UI.addToast("Não foi possível alterar a subscrição!");
          });
        }
      }).catch((error) => {
        console.log(error);

        let startSubscription = moment().format();
        let endSubscription = moment().format();

        p.startSubscription = startSubscription;
        p.endSubscription = endSubscription;
        // No record was found, create a new one
        this.ClientsMagazines.create({
          active: 1,
          id: 0,
          magazineId: p.id,
          clientId: this.id,
          modalidadesId: 6,
          moderatorId: 0,
          start: startSubscription,
          end: endSubscription,
          comments: '',
          metodoPagamento: 'Multibanco',
          dataSincronizacaoPhc: moment().format(),
          gdpr: 1
        }).$promise.then(res => {
          this.UI.addToast("A subscrição foi alterada com sucesso!");
          location.reload(true);
          this.getDetalhesCliente(this.id);
        });
      });
    } else {
      // We have a subscription already, put active to 0
      this.ClientsMagazines.findOne({
        filter: {
          where: {
            magazineId: p.id,
            clientId: this.id
          }
        }
      }).$promise.then((res) => {
        if (res != null) {
          // Exists one record for this client - we need to update fields and save
          res.active = 0;
          p.startSubscription = null;
          p.endSubscription = null;
          // Save and update stuff
          res.$save().then(() => {
            this.UI.addToast("A subscrição foi alterada com sucesso!");
            location.reload(true);
            this.getDetalhesCliente(this.id);
          }).catch((error) => {
            this.UI.addToast("Não foi possível alterar a subscrição!");
          });
        }
      }).catch((error) => {
        this.UI.addToast("Ocorreu um erro. Tente novamente!");
        this.getDetalhesCliente(this.id);
        console.log(error);
      });
    }
  };

  setSubscriptions = () => {
    for (let i = 0; i < this.magazines.length; i++)
      // Create array where if subscription exists for client, set array value to true else set to false
      this.subscriptions.push(this.client.CliMagazineRel.findIndex((e) => e.magazineId == this.magazines[i].id) !== -1);
  };

  // Check if magazine or 1st page is checked by the user
  checkClientMagazine = (m) => {
    if (this.client == null || this.client.CliMagazineRel == null || this.client.CliMagazineRel.length === 0)
      return false;

    for (let i = 0; i < this.client.CliMagazineRel.length; i++) {
      if (m.id === this.client.CliMagazineRel[i].magazineId) {
        return true;
      }
    }
    return false;
  };

  ativarRgpd = (a) => {
    let options = {
      size: 'lg',
      template: require('./rgpd.view.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {

        $scope.palavraInserida = "";

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogRemove = this.UI.showDialog(options);

    dialogRemove.then((res) => {
      if (this.palavraEsquecimento === res.palavraInserida) {
        // User inserted correct word, we have permission to delete
        a.rgpd = 0;
        a.active = 0;
        a.$save().then((r) => {
          this.getDetalhesCliente(this.id);
          this.UI.addToast("Esquecimento realizado com sucesso.");
          this.$state.go('app.clients.list');
        }).catch((error) => {
          // User inserted the wrong word. Do nothing
          this.UI.addToast("Erro no registo de esquecimento. Por favor tente mais tarde.");
          console.log(error);
        });
      } else {
        this.UI.addToast("Esquecimento não realizado. Por favor siga as instruções.");
      }
    });
  };
}
ClientesDetalhesController.$inject = ['Clients', 'ClientsDevices', 'Magazines', 'GroupNewsstand', 'ClientsMagazines', 'NgTableParams', '$rootScope', '$state', '$stateParams', '$filter', 'UIService', 'AuthenticationService'];
