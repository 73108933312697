export default class NoticiasAddController {
  constructor(AuthenticationService, AuthorizationService, UIService, $state, $scope, News, Issues, Timelines, Domains, Files, FileUploader, Notification) {
    this.Auth = AuthenticationService;
    this.Authorization = AuthorizationService;
    this.UI = UIService;
    this.News = News;
    this.Notification = Notification;

    this.Timelines = Timelines;
    this.Issues = Issues;
    this.Domains = Domains;
    this.Files = Files;
    this.$state = $state;
    this.FileUploader = FileUploader;

    this.loadData();
    // Control variables
    this.pubDates = [{
        name: "Publicar agora",
        value: 0
      },
      {
        name: "Publicar hoje, às 06:00",
        value: 1
      },
      {
        name: "Publicar amanhã, às 06:00",
        value: 2
      },
      {
        name: "Arquivo",
        value: 3
      },
    ];
    this.auxPubdate = {
      selected: null
    };
    this.form = {};
    this.news = {
      id: 0,
      userId: this.Auth.getId(),
      active: true,
      createdate: new Date(),
    };
    this.file = {
      id: 0,
      filetype: 'jpg',
      userId: this.Auth.getId(),
      active: true
    };
    this.hasImage = false;
    this.isFeatured = false;
    this.auxDominio = {
      selected: null
    };
    // Cropper API
    this.pmdCropper = {};
    $scope.$watch(() => {
      return this.pmdCropper.ready;
    }, (a) => {
      this.hasImage = a;
    });
    // Uploader settings
    this.uploader = new this.FileUploader({
      url: '/api/assets/upload/news',
      queueLimit: 1,
    });
    // Force items to be only image
    let filter = {
      name: 'verifyIMG',
      fn: function (item, options) {
        return item.type.indexOf("image/") !== -1;
      }
    };
    this.uploader.filters.push(filter);
    // Register news if image uploaded ok
    this.uploader.onSuccessItem = (item, response) => {
      this.fileUploadedOK = true;
      this.file.container = response.result.container;
      this.file.year = response.result.year;
      this.file.month = response.result.month;
      this.file.name = response.result.name;
      this.registerNews();
    };
    // Image upload failed
    this.uploader.onErrorItem = (response, status, headers) => {
      this.fileUploadedOK = false;
      this.UI.addToast('Erro ao carregar imagem');
      this.UI.showConfirm('Ocorreu um erro a carregar imagem, deseja inserir notícia sem imagem?').then(r => {
        if (r) {
          this.registerNews();
        }
      }).catch(() => {});
    };
  };

  getImage = () => {
    let dataURItoBlob = (dataURI) => {
      let byteString = atob(dataURI.toString().split(',')[1]);
      //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      //or mimeString if you want
      return new Blob([ab], {
        type: 'image/jpeg'
      });
    };
    this.file.userId = this.Auth.getId();
    let base64 = this.pmdCropper.export();
    let blob = dataURItoBlob(base64);
    let name = `${this.UUID()}.jpg`;
    let file = new File([blob], name);
    // Create "dummy" file and add it to the uploader
    let dummy = new this.FileUploader.FileItem(this.uploader, {
      lastModifiedData: new Date(),
      type: 'image/jpeg',
      name: name
    });
    dummy._file = file;
    this.uploader.queue = [];
    this.uploader.queue.push(dummy);
    this.uploader.uploadAll();
  };

  pickImage = () => {
    angular.element('#fileinput').trigger('click');
  };

  clearImage = () => {
    this.hasImage = false;
    this.pmdCropper.clear();
  };

  loadData = () => {
    this.Domains.find({
      filter: {
        where: {
          id: {
            inq: this.Authorization.getDomains()
          }
        }
      }
    }).$promise.then(res => {
      this.dominios = res;
      if (res.length === 1) {
        this.auxDominio.selected = res[0];
      }
    });
  };

  UUID = () => {
    let uuid = "",
      i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  submit = () => {
    if (this.form.addNoticia.$invalid) {
      this.UI.addToast('Existem erros no preenchimento do formulário');
    } else {
      if (this.hasImage) {
        this.getImage();
      } else {
        if (this.news.featured) {
          this.UI.addToast('É necessária uma imagem nas notícias de destaque');
        } else {
          this.registerNews();
        }
      }
    }
  };

  registerNews = () => {
    // Check if this is really used...
    if (this.news.toshare) {
      this.news.tosharedc = true;
      this.news.toshareda = true;
      this.news.tosharedl = true;
      this.news.tosharedv = true;
    }
    this.news.domainId = this.auxDominio.selected.id;

    let potencialIssueDate;
    if (this.auxPubdate.selected.value === 0 || this.auxPubdate.selected.value === 1) {
      potencialIssueDate = moment.utc().hour(2).minute(0).second(0).millisecond(0).toISOString();
    } else {
      if (this.auxPubdate.selected.value === 2) {
        potencialIssueDate = moment.utc().add(1, 'd').hour(2).minute(0).second(0).millisecond(0).toISOString();
      } else {
        potencialIssueDate = moment.utc().hour(2).minute(0).second(0).millisecond(0).toISOString();
      }
    }
    this.Issues.find({
      filter: {
        where: {
          domainId: this.news.domainId,
          dateIssued: potencialIssueDate,
          active: 1
        },
        limit: 1
      }
    }).$promise.then((res) => {
      //Se edição existe utiliza essa edição, caso contrário cria uma nova
      if (res.length === 1) {
        this.news.issueId = res[0].id;
        this.createNew();
      } else {
        let newMagazineId;
        switch (this.news.domainId) {
          case 2:
            newMagazineId = 1;
            break;
          case 3:
            newMagazineId = 3;
            break;
          case 4:
            newMagazineId = 4;
            break;
          case 5:
            newMagazineId = 2;
            break;
        }
        let domain = this.auxDominio.selected;
        let url = domain.homeUrl.split(".");
        let reverseDns = `${url[url.length - 1]}.${url[url.length - 2]}`;
        let m = moment().utc();
        let year = m.format('YYYY');
        let month = m.format('MM');
        this.Issues.create({
          id: 0,
          uuid: this.UUID(),
          title: moment(potencialIssueDate).format("DD/MM/YYYY"),
          appleCode: `${reverseDns}.issue${moment(potencialIssueDate).format('YYMMDD')}`,
          dateIssued: potencialIssueDate,
          magazineId: newMagazineId,
          domainId: this.news.domainId,
          price: 0.89,
          enabled: true,
          mancheteId: null,
          url: null,
          urlCover: null,
          size: null,
          active: true
        }).$promise.then((issue) => {
          this.news.issueId = issue.id;
          this.createNew();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro a criar edição');
        });
      }
    }).catch((error) => {
      console.log(error);
    })
  };

  createNew = () => {
    this.News.create(this.news).$promise.then(news => {

      this.file.newId = news.id;
      if (this.fileUploadedOK) {
        this.file.domainId = this.auxDominio.selected.id;
        // For now...
        this.file.path = `api/assets/download/${this.file.container}/${this.file.year}/${this.file.month}/${this.file.name}`;
        this.file.pathCompressed = this.file.path;
        this.file.pathThumbnail = this.file.path;
        this.Files.create(this.file).$promise.then(() => {
          //pubDate = 3 (quando seleciona "Arquivo" no pmd-radio)
          if (this.auxPubdate.selected.value !== 3) {
            this.registerTimeline();
          } else {
            this.leave();
          }
        });
      } else {
        if (this.auxPubdate.selected.value !== 3) {
          this.registerTimeline();
        } else {
          this.leave();
        }
      }
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro a adicionar notícia');
    });
  };

  leave = () => {
    this.UI.addToast('Notícia adicionada com sucesso');
    this.$state.go('app.noticias.list');
  };

  registerTimeline = () => {
    let pubDates = [
      moment.utc(),
      moment.utc().hour(6).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ssZ"),
      moment.utc().add(1, 'd').hour(6).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ssZ"),
      null,
    ];

    if (this.auxPubdate.selected.value === 0) {
      let minDate = moment.utc().hour(0).minute(0).second(0).millisecond(0);
      let maxDate = moment.utc().hour(6).minute(0).second(0).millisecond(0);

      if (moment.utc().isBetween(minDate, maxDate)) {
        this.UI.addToast('A notícia será publicada hoje a partir das 06:00h.');
        pubDates[this.auxPubdate.selected.value] = moment.utc().hour(6).minute(0).second(0).millisecond(0);
      }
    }

    let validateDate = pubDates[this.auxPubdate.selected.value];
    validateDate = moment(validateDate);
    console.log("Validating DST time...");
    if (validateDate.isDST()) {
      console.log("Adding 1h due to DST");
      validateDate.add(1, 'h');
    }

    this.Timelines.create({
      id: 0,
      pubdate: validateDate,
      featured: this.isFeatured,
      newId: this.file.newId,
      domainId: this.auxDominio.selected.id,
      userId: this.Auth.getId(),
      active: true
    }).$promise.then((res) => {
      if (this.notify && this.auxPubdate.selected.value === 0) {
        this.Notification.create({
          id: 0,
          domainId: this.auxDominio.selected.id,
          newsId: res.id,
          estado: 2,
          data: moment(),
          enviada: 0,
          tentativas: 0,
          titulo: this.news.title,
          mensagem: "Clique para saber mais"
        }).$promise.then(r => {
          this.UI.addToast('Notificação criada!');
          this.leave();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao criar notificação');
          this.leave();
        });
      } else {
        this.leave();
      }
    }).catch((error) => {
      this.UI.addToast('Ocorreu um erro a agendar notícia');
    });
  };
};

NoticiasAddController.$inject = ['AuthenticationService', 'AuthorizationService', 'UIService', '$state', '$scope', 'News', 'Issues', 'Timelines', 'Domains', 'Files', 'FileUploader', 'Notification'];
