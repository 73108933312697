export default class DominioController {
  constructor(Domains, $http, NgTableParams, $rootScope, $state, $filter, FileUploader, UIService) {
    this.dominio = null;
    this.$http = $http;
    this.Domains = Domains;
    this.dominioLoading = true;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.$rootScope = $rootScope;
    this.FileUploader = FileUploader;
    this.$state = $state;
    this.$filter = $filter;
    this.getDominio();
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  updateAnexo = (ok) => {
    this.Domains.findOne({
      filter: {
        where: {
          id: ok.dominio.id,
          active: 1
        }
      }
    }).$promise.then((c) => {
      // If there is no file yet, just upload it
      if (c.logoPath == null) {
        c.logoPath = ok.newFileName;
      } else {
        // There's a file already there. Change it.
        if (ok.newFileName) {
          // Remove old photo
          this.$http.delete('/api/Upload/files/files/domains/' + c.logoPath);
          c.logoPath = ok.newFileName;
        } else
          c.logoPath = null;
      }
      ok.uploader.uploadAll();
      c.$save().then((res) => {
        this.getDominio();
      }, (error) => {
        this.UI.addToast("Erro na gravação da imagem. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log("ERRO: ", error);
      this.UI.addToast("De momento não é possível adicionar a imagem. Por favor tente mais tarde.");
    });
  };

  createDominio = () => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarDominio.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.dominio = {};
        $scope.label = "Adicionar jornal";

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/assets/upload/domains',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          item.file.name = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onSuccessItem = (item, response) => {
          $scope.dominio.iconPath = `/api/assets/download/${response.result.container}/${response.result.year}/${response.result.month}/${response.result.name}`;
          $dialog.close($scope);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem válida");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("Ocorreu um erro ao carregar imagem");
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {

      this.Domains.create({
        id: 0,
        name: ok.dominio.name,
        logoPath: ok.newFileName,
        iconPath: ok.dominio.iconPath,
        homeUrl: ok.dominio.homeUrl,
        localUrl: ok.dominio.homeUrl + 'local',
        facebookUrl: ok.dominio.facebookUrl,
        twitterUrl: ok.dominio.twitterUrl,
        location: ok.dominio.location,
        active: 1
      }).$promise.then((res) => {
        if (ok.newFileName) {
          // File exists, upload it
          ok.uploader.uploadAll();
          this.getDominio();
        } else {
          // No file, just update the list
          this.getDominio();
        }
        this.UI.addToast("Jornal adicionado com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro a criar jornal!");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editDominio = (appEdit) => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./adicionarDominio.view.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.dominio = {};
        $scope.dominio.id = appEdit.id;
        $scope.dominio.name = appEdit.name;
        $scope.dominio.location = appEdit.location;
        $scope.dominio.logoPath = appEdit.logoPath;
        $scope.dominio.iconPath = appEdit.iconPath;
        $scope.dominio.homeUrl = appEdit.homeUrl;
        $scope.dominio.localUrl = appEdit.localUrl;
        $scope.dominio.facebookUrl = appEdit.facebookUrl;
        $scope.dominio.twitterUrl = appEdit.twitterUrl;

        $scope.label = "Editar jornal";
        $scope.newFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/assets/upload/domains',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };

        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          item.file.name = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onSuccessItem = (item, response) => {
          $scope.dominio.iconPath = `/api/assets/download/${response.result.container}/${response.result.year}/${response.result.month}/${response.result.name}`;
          $dialog.close($scope);
        };


        $scope.ok = () => {
          $scope.uploader.uploadAll();
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((a) => {

      this.Domains.findOne({
        filter: {
          where: {
            id: a.dominio.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.name = a.dominio.name;
        res.location = a.dominio.location;
        res.logoPath = a.dominio.logoPath;
        res.iconPath = a.dominio.iconPath;
        res.homeUrl = a.dominio.homeUrl;
        res.localUrl = a.dominio.localUrl;
        res.facebookUrl = a.dominio.facebookUrl;
        res.twitterUrl = a.dominio.twitterUrl;
          res.$save().then((r) => {
          if (a.newFileName)
            this.updateAnexo(a);
          else
            this.getDominio();
          this.UI.addToast("Jornal editado com sucesso!");
        }).catch(err => {
          this.UI.addToast("Erro a editar jornal!");
          console.log(err);
        });
      }).catch((error) => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
          console.log(error);
      });
    });
  };

  removerDominio = (a) => {
    this.Domains.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((res) => {
      let options = {
        size: 'md',
        template: require('./removerDominio.view.html'),
        controller: ['$scope', '$dialog', ($scope, $dialog) => {

          $scope.ok = () => {
            $dialog.close(res);

          };
          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };

      let dialogRemove = this.UI.showDialog(options);

      dialogRemove.then((result) => {
        result.active = 0;
        result.$save().then((r) => {
          this.UI.addToast("Jornal removido com sucesso!");
          this.getDominio();
        }).catch((error) => {
            console.log(error);
          }
        ).catch((error) => {
          console.log(error);
        })
      }).catch((err) => {
        console.log(err);
      });
    })
  };

  getDominio = () => {
    this.dominioLoading = true;
    this.Domains.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.dominio = res;

      this.table = new this.NgTableParams({
        count: 20,
        sorting: {
          name: 'asc'
        }
      }, {
        dataset: this.dominio
      });

      this.dominioLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };
};


DominioController.$inject = ['Domains', '$http', 'NgTableParams', '$rootScope', '$state', '$filter', 'FileUploader', 'UIService'];
