import {routes} from "./routes";
import NoticiasAddController from "./add/add.controller";
import NoticiasViewController from "./view/view.controller";
import NoticiasListController from "./list/list.controller";
import NoticiasEditController from "./edit/edit.controller";

export default angular.module('app.news', [])
  .config(routes)
  .controller('NoticiasAddController', NoticiasAddController)
  .controller('NoticiasEditController', NoticiasEditController)
  .controller('NoticiasListController', NoticiasListController)
  .controller('NoticiasViewController', NoticiasViewController)
  .name;
