window.$ = window.jQuery;
/* Angular Dependencies */
import angular from 'angular';
let uiRouter = require("@uirouter/angularjs").default;
import '@uirouter/angularjs/release/stateEvents';
import 'angular-file-upload';
// This is intended
import pdfJsLib from 'pdfjs-dist/build/pdf.min';
import pdfJsLibWorker from 'pdfjs-dist/build/pdf.worker.entry';
//...
import angularLocalePT from "angular-i18n/pt-pt";
import ngMessages from 'angular-messages';
import ngResource from "angular-resource";
import ngSanitize from "angular-sanitize";
import textAngular from "textangular";
import ngChart from "angular-chart.js"
let uiSelect = require('ui-select');
/* Drag and Drop stuff */
require('webpack-jquery-ui/sortable');
require('angular-ui-sortable');
/* Template modules */
import config from './app.config';
import {ApplicationInterceptor} from "./app.interceptor";
import { ngTableModule } from 'ng-table/bundles/ng-table';
/* Client Modules */
import lbServices from './modules/loopback/lbServices';
import Interface from './modules/interface';
import Authentication from './modules/authentication';
import Administration from './modules/administration';
import Authorization from './modules/authorization';
import Teaser from './modules/teasers';
import News from './modules/news';
import Issues from './modules/issues';
import Notifications from './modules/notifications';
import Ads from './modules/ads';
// Set moment locale to Portugal globally. It works :)
moment.locale('pt');
// All CSS requirements by directives and others shall be imported HERE
require('ng-table/bundles/ng-table.min.css');
require('material-icons/iconfont/material-icons.css');
require('@mdi/font/css/materialdesignicons.min.css');

/* App global inject */
let app = angular.module('app', [
  uiRouter,
  'ui.router.state.events',
  ngResource,
  ngSanitize,
  ngMessages,
  uiSelect,
  textAngular,
  angularLocalePT,
  'angularFileUpload',
  'ui.sortable',
  ngTableModule.name,
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  Teaser,
  News,
  Issues,
  Notifications,
  Ads
]);

app.config(config);
app.run(ApplicationInterceptor);
// Configurações selectors
app.config(['uiSelectConfig', function(uiSelectConfig) {
  uiSelectConfig.theme = 'bootstrap';
  uiSelectConfig.resetSearchInput = false;
  uiSelectConfig.skipFocusser = true;
}]);
app.run(['ngTableDefaults', function (ngTableDefaults) {
  ngTableDefaults.params.setPager = false;
  ngTableDefaults.settings.counts = [10, 25, 50, 100];
  ngTableDefaults.params.page = 1;
}]);
app.run(['$templateCache', function($templateCache) {
  $templateCache.put('ng-table/filters/text.html', require('./modules/interface/templates/text.html'));
  $templateCache.put('ng-table/filters/number.html', require('./modules/interface/templates/number.html'));
  $templateCache.put('ng-table/filters/select.html', require('./modules/interface/templates/select.html'));
  $templateCache.put('ng-table/pager.html', require('./modules/interface/templates/pager.html'));
}]);

// TextAngular config
app.config(['$provide', function($provide) {
  $provide.decorator('taOptions', ['$delegate', function(taOptions) {
    // $delegate is the taOptions we are decorating
    // here we override the default toolbars and classes specified in taOptions.
    taOptions.forceTextAngularSanitize = false; // set false to allow the textAngular-sanitize provider to be replaced
    taOptions.keyMappings = []; // allow customizable keyMappings for specialized key boards or languages
    taOptions.toolbar = [
      ['bold', 'italics', 'underline'], ['ul', 'outdent', 'indent'], ['redo', 'undo', 'clear'],
    ];
    taOptions.classes = {
      focussed: '',
      toolbar: 'btn-toolbar',
      toolbarGroup: 'btn-group',
      toolbarButton: 'btn btn-xs btn-default pmd-btn-flat pmd-ripple-effect',
      toolbarButtonActive: 'active',
      disabled: 'disabled',
      textEditor: 'form-control',
      htmlEditor: 'form-control',
    };
    return taOptions; // whatever you return will be the taOptions
  }]);
  // Replace css icons
  $provide.decorator('taTools', ['$delegate', function(taTools) {
    taTools.bold.iconclass = ' mdi mdi-format-bold';
    taTools.italics.iconclass = 'mdi mdi-format-italic';
    taTools.underline.iconclass = ' mdi mdi-format-underline';
    taTools.ul.iconclass = ' mdi mdi-format-list-bulleted';
    taTools.indent.iconclass = ' mdi mdi-format-indent-increase';
    taTools.outdent.iconclass = ' mdi mdi-format-indent-decrease';
    taTools.undo.iconclass = ' mdi mdi-undo';
    taTools.redo.iconclass = ' mdi mdi-redo';
    taTools.clear.iconclass = ' mdi mdi-format-clear';
    taTools.html.iconclass = ' mdi mdi-code-tags';
    return taTools;
  }]);
}]);
