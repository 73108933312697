require("cropit");

export default angular
  .module("app.interface.cropper", [])
  .directive("pmdCropper", [
    () => {
      return {
        restrict: "A",
        scope: {
          image: "@image",
          api: "=api",
          options: "=options",
        },
        link: (scope, element, attrs) => {
          scope.$watch(
            () => scope.options,
            () => {
              scope.hasImage = angular.isDefined(scope.image) ? true : false;

              scope.hasOptions = angular.isDefined(scope.options)
                ? true
                : false;

              scope.api.ready = false;

              let options = {
                imageBackground: true,
                width: scope.hasOptions ? scope.options.width : 623,
                height: scope.hasOptions ? scope.options.height : 413,
                allowDragNDrop: false,
                onImageLoaded: () => {
                  scope.api.ready = true;
                  scope.$apply();
                },
              };

              if (scope.hasImage) {
                options.imageState = {
                  src: scope.image,
                };
              }

              element.cropit(options);

              scope.api.export = () => {
                return element.cropit("export", {
                  type: "image/jpeg",
                  quality: ".9",
                  originalSize: true,
                });
              };

              scope.api.clear = () => {
                scope.hasImage = false;
                scope.api.ready = false;
                angular
                  .element(".cropit-preview")
                  .removeClass("cropit-image-loaded");
                angular.element(".cropit-preview-image").removeAttr("style");
                angular.element(".cropit-preview-image").attr("src", "");
              };

              scope.api.rotateCW = () => {
                element.cropit("rotateCW");
              };

              scope.api.rotateCCW = () => {
                element.cropit("rotateCCW");
              };
            }
          );
        },
      };
    },
  ]).name;
