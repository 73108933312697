export function routes($stateProvider) {
    $stateProvider
      .state('app.notifications', {
        url: '/notifications',
        abstract: true,
        template: '<ui-view></ui-view>'
      })
      .state('app.notifications.list', {
        url: '?{page:int}&{term:string}&{order:string}&{sort:string}',
        template: require('./list/view.html'),
        controller: 'NotificationListController',
        controllerAs: 'vm'
      })
      .state('app.notifications.details', {
        url: '/{id}',
        template: require('./view/view.html'),
        controller: 'NotificationDetailsController',
        controllerAs: 'vm'
      })
      .state('app.notifications.create', {
        url: '/add',
        template: require('./view/view.html'),
        controller: 'NotificationDetailsController',
        controllerAs: 'vm'
      })
    ;
  }
  
  routes.$inject = ['$stateProvider'];
  