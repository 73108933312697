import {
  routes
} from './routes';
import NotificationService from "./service";
import NotificationListController from "./list/controller";
import NotificationDetailsController from "./view/controller";

export default angular.module('app.mongodb.notifications', [])
  .config(routes)
  .service('NotificationService', NotificationService)
  .controller('NotificationListController', NotificationListController)
  .controller('NotificationDetailsController', NotificationDetailsController)
  .name;
